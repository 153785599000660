import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import * as _ from 'lodash';
import { SubscriptionPlanModel } from 'src/app/models/subscription-plan-model';
import { CheckoutFormComponentStore } from 'src/app/stores/components/checkout/checkout.component-store';
import { PaymentRequestModel } from 'src/app/models/payment-request-model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { filter, Subject, takeUntil } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerDialogComponent } from '../dialogs/spinner-dialog/spinner-dialog.component';
import { environment } from 'src/environments/environment';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { ValidationException } from '../../exceptions/validation-exception';
import { PaymentRequestActiveSubscriptionPlanOrderException } from '../../exceptions/payment-request-active-subscription-plan-order-exception';
import { MatSnackBar } from '@angular/material/snack-bar';
import { loadStripe } from '@stripe/stripe-js';
import { CreateCheckoutException } from '../../exceptions/create-checkout-exception';
import { PriceModel } from 'src/app/models/price-model';
import { number } from 'echarts';

export class SubscriptionPlanViewModel {
  id: string;
  title: string;
  description: string;

  constructor(id: string, title: string, description: string) {
    this.id = id;
    this.title = title;
    this.description = description;
  }
}

export class PriceViewModel {
  id: string;
  interval: string
  amount: string;
  currency: string;
  recurringTye: string;
  total: number;
  quantity : number;

  constructor(id: string, amount: number, currency: string, interval: string, quantity: number) {
    this.id = id;
    this.amount = (amount / 100.0).toString();
    this.currency = currency == "eur" ? "€" : "$";
    this.interval = interval;
    this.total = (amount * quantity) / 100.0;
    this.quantity = quantity;
  }
}

export class PropositionViewModel {
  subscriptionPlanName: string;
  propositionDescription: string;

  constructor(subscriptionPlanName: string, propositionDescription: string) {
    this.subscriptionPlanName = subscriptionPlanName;
    this.propositionDescription = propositionDescription;
  }
}

@Component({
  selector: 'app-pricing-subscription-plan',
  templateUrl: './pricing-subscription-plan.component.html',
  styleUrls: ['./pricing-subscription-plan.component.scss']
})
export class PricingSubscriptionPlanComponent implements OnInit {
  stripePromise = loadStripe(environment.stripe_apiKey);
  
  @Input()
  subscriptionPlanType: string;

  @Input()
  subscriptionPlan: SubscriptionPlanModel;

  @Input()
  recurringType: string;

  @Input()
  quantity : number;

  enabledCheckout: boolean = environment.enabledCheckout;
  isAuthenticated: boolean;
  subscriptionPlanViewModel: SubscriptionPlanViewModel;
  priceViewModel: PriceViewModel;

  supportFeatureViewModels = [];
  propositionViewModels = [];

  ngUnsubscribeState = new Subject<void>();
  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;

  constructor(
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly checkoutComponentStore: CheckoutFormComponentStore,
    private readonly spinnerService: SpinnerService,
    private readonly pageScrollService: PageScrollService,
    private readonly snackBar: MatSnackBar,
    @Inject(DOCUMENT) private readonly document: Document
  ) { }

  ngOnInit(): void {
    this.subscriptionPlanViewModel = this.getSubscriptionPlanViewModel();
    this.priceViewModel = this.getPrice();

    this.oidcSecurityService.isAuthenticated$.subscribe(({ isAuthenticated }) => {
      this.isAuthenticated = isAuthenticated;
    });

    this.checkoutComponentStore.paymentInformation$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(async (paymentInformation) => {
      // Redirect to the Stripe page.
      const stripe = await this.stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: paymentInformation.sessionId,
      });
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
      if (error) {
        console.log(error);
        this.snackBar.open(error.message, "Dismiss");
      }
    });

    this.checkoutComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });

    this.checkoutComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        if (errorMessage instanceof ValidationException) {
          const validationViolationMessage = errorMessage.violations.map(v => v.field + ' ' + v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else if (errorMessage instanceof PaymentRequestActiveSubscriptionPlanOrderException
          || errorMessage instanceof CreateCheckoutException
        ) {
          this.snackBar.open("Checkout Failure. An active subscription plan order is already present. Please refresh the page.", "Dismiss");
        }
        else {
          this.snackBar.open("Something went wrong. Please try again.", "Dismiss");
        }
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.recurringType?.currentValue
      ||
      changes?.quantity?.currentValue){
      this.priceViewModel = this.getPrice();
    }
  }

  async onSubmit() {
    this.showSpinner();
    const paymentRequest = new PaymentRequestModel();
    paymentRequest.priceId = this.priceViewModel.id.trim();
    paymentRequest.quantity = this.quantity ?? 1;
    this.checkoutComponentStore.doCheckout({ paymentRequestData: paymentRequest });
  }

  public isAnnually(): boolean {
    return this.recurringType.toUpperCase() != 'MONTHLY';
  }

  public isCheckoutEnabled(): boolean {
    return this.enabledCheckout;
  }

  public isBasicPlan(): boolean {
    return this.subscriptionPlan.name === "Starters";
  }

  public isStandardPlan(): boolean {
    return this.subscriptionPlan.name === "Main";;
  }

  public scrollToComparePlans(): void {
    // HACK: In order to scroll to a certain anchor, you need to do this manually.
    // Since the mat-sidenav is in place and you need to do the scrolling inside the 
    // mat-sidenav-content.
    let sideNavContent = document.getElementsByTagName('mat-sidenav-content')[0];
    let pageScrollInstance = this.pageScrollService.create({
      document: this.document,
      scrollTarget: '#pricing-compare-plans',
      scrollViews: [sideNavContent],
      speed: 1,
      duration: 300,
      interruptible: false
    });
    this.pageScrollService.start(pageScrollInstance);
  }

  private getPrice(): PriceViewModel {
    if (this.recurringType.toUpperCase() == 'MONTHLY') {
      return this.getMonthlyPrice();
    }
    return this.getAnnualPrice();
  }

  private getMonthlyPrice(): PriceViewModel {
    const result = _.filter(this.subscriptionPlan.prices, function (price) {
      return price.recurring.interval == "month";
    }) as PriceModel[];
    const tieredPrice = this.getTieredPrice(result[0], this.quantity);
    return new PriceViewModel(result[0].externalId, tieredPrice, result[0].currency, result[0].recurring.interval, this.quantity);
  }

  private getAnnualPrice(): PriceViewModel {
    const result = _.filter(this.subscriptionPlan.prices, function (price) {
      return price.recurring.interval == "year";
    }) as PriceModel[];

    const tieredPrice = this.getTieredPrice(result[0], this.quantity);
    return new PriceViewModel(result[0].externalId, tieredPrice, result[0].currency, result[0].recurring.interval, this.quantity);
  }

  private getTieredPrice(price : PriceModel , quantity : number) : number {
    // Find the right tier based on quantity;
    // Sort the tiered price on upToVolume
    const sortedTiers = _.sortBy(price.tiers, ['upToVolume']);
    // Return the first upToVolume if the quantity is smaller or equal.
    const tieredPrice = _.find(sortedTiers, function (tieredPrice) {
      if(tieredPrice.upToVolume){
        return quantity <= tieredPrice.upToVolume;
      }
      else {
        return quantity <= number.MAX_SAFE_INTEGER;
      }
    });
    return tieredPrice.amount;
  }

  private getSubscriptionPlanViewModel(): SubscriptionPlanViewModel {
    return new SubscriptionPlanViewModel(this.subscriptionPlan.externalId, this.subscriptionPlan.name, this.subscriptionPlan.description);
  }

  private showSpinner() {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
