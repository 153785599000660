<div class="container mat-component-background">
    <h1 mat-dialog-title>Wondering why we are asking for a 16-character password?</h1>
    <div mat-dialog-content>
        <p>
            We want your account-details as safe as possible, and a 16-character password takes a couple of billion
            years to crack!</p>
        <br />
        <p>
            We’ll let you in on our secret recipe to create a solid, easy to remember password:
        </p>
        <ul>
            <li>Step 1: Memorize 3 words (6 is better) and capitalize each word.</li>
            <li>Step 2: Put a digit or digits somewhere in the mix</li>
            <li>Step 3: Use a special character to separate each word.</li>
        </ul>
        <br />
        <p>
            Here are some examples:
        </p>
        <ul class="content-password-examples">
            <li><em>How-I-Would-Never-Go-Vegan-In-2016</em></li>
            <li><em>What-Do-Vegan5-Actually-Eat?</em></li>
        </ul>

        <br />
        <p>
            Or to make things even easier, we totally recommend using a password manager of your choice!
        </p>

    </div>
    <div mat-dialog-actions class="container-actions">
        <button mat-raised-button color="primary" (click)="onConfirmClick()" cdkFocusInitial>
            Ok
        </button>
    </div>
</div>