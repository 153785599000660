import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ResponsiveBaseComponent } from 'src/app/core/components/responsive-base/responsive-base.component';
import { CustomerStorySummaryModel } from 'src/app/models/viewmodels/customer-story-summary-model';

@Component({
  selector: 'app-customer-stories',
  templateUrl: './customer-stories.component.html',
  styleUrl: './customer-stories.component.scss'
})
export class CustomerStoriesComponent extends ResponsiveBaseComponent {
  private readonly customerStories: CustomerStorySummaryModel[] = [
    {
      routerLink: 'beesechurgers',
      title: 'Beesechurgers',
      description: 'From paper to digital stamp cards.',
      readingTime: '5 min',
      companyType: 'Vegan',
      companyCategory : 'Fast Food Restaurant',
      companyLogoUrl: '/assets/img/partners/beesechurgers.png',
      storyImageUrl: '/assets/img/partners/stories/beesechurgers.jpg',
    },
    {
      routerLink: 'miss-nice-banana',
      title: 'Miss Nice Banana',
      description: 'Creating real connections by rewarding loyal customers.',
      readingTime: '5 min',
      companyType: 'Vegan',
      companyCategory: 'Restaurant',
      companyLogoUrl: '/assets/img/partners/miss-nice-banana.png',
      storyImageUrl: '/assets/img/partners/stories/miss-nice-banana.jpg',
    },
    {
      routerLink: 'vegan-deli-cioso',
      title: 'Vegan Deli Cioso',
      description: 'To build a strong customer base, we need to show appreciation.',
      readingTime: '3 min',
      companyType: 'Vegan',
      companyCategory : 'Cafe Coffee & Tea House',
      companyLogoUrl: '/assets/img/partners/vegan-deli-cioso.png',
      storyImageUrl: '/assets/img/partners/stories/vegan-deli-cioso.jpg',
    },
  ];
  constructor(responsive: BreakpointObserver) {
    super(responsive);
  }

  public getCustomerStorySummaries(): CustomerStorySummaryModel[] {
    return this.customerStories;
  }
}
