import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CreateCampaignModel } from 'src/app/models/create-campaign-model';
import { CreateCampaignComponentStore } from 'src/app/stores/components/campaign-create/create-campaign.component-store';
import { EndDateAfterStartDate } from '../../validators/start-end-date.validator';
import { VoucherDuration } from 'src/app/models/voucher-duration-enum';
import { VoucherDurationP1M, VoucherDurationP1Y, VoucherDurationP2Y, VoucherDurationP3M, VoucherDurationP3Y, VoucherDurationP6M } from 'src/app/models/campaign-model';
import { ValidationException } from '../../exceptions/validation-exception';
import { MaximumActiveCampaignsExceededException } from '../../exceptions/maximum-active-campaigns-exceeded-exception';
import { CampaignLogoComponentStore } from 'src/app/stores/components/campaign-logo/campaign-logo.component-store';
import { SpinnerService } from 'src/app/services/spinner.service';
import { MatDialogRef } from '@angular/material/dialog';
import { UploadPhotoModel } from 'src/app/models/upload-photo-model';
import { SpinnerDialogComponent } from '../dialogs/spinner-dialog/spinner-dialog.component';
import * as _ from 'lodash';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

interface VoucherDurationViewModel {
  value: VoucherDuration,
  viewValue: string,
}

@Component({
  selector: 'app-campaign-create',
  templateUrl: './campaign-create.component.html',
  styleUrls: ['./campaign-create.component.scss']
})
export class CampaignCreateComponent implements OnInit {
  @Input()
  public campaignDetailsFormGroup: UntypedFormGroup;
  
  @Input()
  public campaignLogoFormGroup: FormGroup;
  
  @Output()
  public onCancelPressed = new EventEmitter();
  
  @Output()
  public onSavePressed = new EventEmitter();
  
  public readonly voucherDurations: VoucherDurationViewModel[] = [
    { value: VoucherDuration.MONTH, viewValue: VoucherDurationP1M },
    { value: VoucherDuration.MONTHS3, viewValue: VoucherDurationP3M },
    { value: VoucherDuration.MONTHS6, viewValue: VoucherDurationP6M },
    { value: VoucherDuration.YEAR, viewValue: VoucherDurationP1Y },
    { value: VoucherDuration.YEARS2, viewValue: VoucherDurationP2Y },
    { value: VoucherDuration.YEARS3, viewValue: VoucherDurationP3Y },
  ];
  public readonly minNameLength: number = 3;
  public readonly maxNameLength: number = 32;
  public readonly minDescriptionLength: number = 3;
  public readonly maxDescriptionLength: number = 255;
  
  public  readonly placeholderUrl = '/assets/img/placeholder.png';
  
  public isImageSaved$: Observable<boolean> = of(false);
  public campaignLogoUrl$: Observable<string>;
  
  private readonly ngUnsubscribeState = new Subject<void>();
  readonly max_size = 538 * 1024;
  private readonly allowed_types = ['image/png', 'image/jpeg'];
  private readonly min_height = 512;
  private readonly min_width = 512;
  private readonly max_height = 512;
  private readonly max_width = 512;
  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;

  private _isXLarge: boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  private companyId: string;
  private campaignId: number;


  constructor(private formBuilder: UntypedFormBuilder,
    private location: Location,
    private createCampaignComponentStore: CreateCampaignComponentStore,
    private readonly campaignLogoComponentStore: CampaignLogoComponentStore,
    private route: ActivatedRoute,
    private router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly spinnerService: SpinnerService,
    private readonly responsive: BreakpointObserver,
  ) { }

  public ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
          result => {
            const breakpoints = result.breakpoints;
    
            this._isXLarge = false;
            this._isLarge = false;
            this._isMedium = false;
            this._isSmall = false;
            this._isXSmall = false;
    
            if (breakpoints[Breakpoints.XSmall]) {
              this._isXSmall = true;
            }
            else if (breakpoints[Breakpoints.Small]) {
              this._isSmall = true;
            }
            else if (breakpoints[Breakpoints.Medium]) {
              this._isMedium = true;
            }
            else if (breakpoints[Breakpoints.Large]) {
              this._isLarge = true;
            }
            else {
              this._isXLarge = true;
            }
          }
        );
    

    this.route.params.subscribe(params => {
      this.companyId = params.companyId;
      this.campaignLogoComponentStore.reset({});
    });

    let startDate = new Date();
    let endDate = new Date();
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    endDate.setDate(endDate.getDate() + 90);

    this.campaignDetailsFormGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(this.minNameLength), Validators.maxLength(this.maxNameLength)]],
      description: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      startDate: [startDate, Validators.required],
      endDate: [endDate, Validators.required],
      stampsObjective: ['5', [Validators.required, Validators.min(1)]],
      campaignRewardName: ['', [Validators.required, Validators.minLength(this.minNameLength), Validators.maxLength(this.maxNameLength)]],
      campaignRewardDescription: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      voucherDuration: [VoucherDuration.YEAR, Validators.required],
      recurrencePattern: [''],
    }, {
      validator: EndDateAfterStartDate('startDate', 'endDate')
    });

    this.campaignLogoFormGroup = this.formBuilder.group({
      uploadedLogo: ['', Validators.required]
    });

    this.createCampaignComponentStore.campaign$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      this.hideSpinner(true);
      if (result) {
        this.campaignId = result.id;
        this.snackBar.open("Stamp card saved successfully.", "Dismiss", {
          duration: 3000
        });
      }
    });

    this.campaignLogoComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });

    this.createCampaignComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      this.hideSpinner(true);
      if (errorMessage !== null && errorMessage !== undefined) {
        if (errorMessage instanceof ValidationException) {
          const validationViolationMessage = errorMessage.violations.map(v => v.field + ' ' + v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else if (errorMessage instanceof MaximumActiveCampaignsExceededException) {
          this.snackBar.open("Can't create a new stamp card. You have already reached the maximum amount your subsription plan provides.", "Dismiss");
        }
        else {
          this.snackBar.open(errorMessage, "Dismiss");
        }
      }
    });

    this.campaignLogoComponentStore.logo$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(logo => {
      this.hideSpinner(true);
      if (logo !== null && logo !== undefined) {
        this.isImageSaved$ = of(true);
        this.campaignLogoUrl$ = of(logo.url);
        this.campaignLogoFormGroup.patchValue({ 'uploadedLogo': true });
        this.snackBar.open("Stamp card image saved successfully.", "Dismiss", {
          duration: 3000
        });
      }
      else {
        this.isImageSaved$ = of(false);
        this.campaignLogoUrl$ = of(this.placeholderUrl);
        this.campaignLogoFormGroup.patchValue({ 'uploadedLogo': '' });
      }
    });
  }

  public ngOnDestroy() : void {
    this.isImageSaved$ = of(false);
    this.campaignLogoUrl$ = of(this.placeholderUrl);
    this.campaignLogoFormGroup.patchValue({ 'uploadedLogo': '' });
    this.campaignLogoComponentStore.reset({});

    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  public ngOnChanges(changes : SimpleChanges) : void {
    if (!changes.campaign.firstChange) {
      this.campaignLogoComponentStore.getCampaignLogo({ companyId: this.companyId, campaignId: this.campaignId });
    }
  }

  public cancel() : void {
    this.location.back();
  }

  public saveCampaign() : void {
    this.campaignDetailsFormGroup.markAllAsTouched();

    if (this.campaignDetailsFormGroup.valid) {
      this.showSpinner();

      var createCampaign = new CreateCampaignModel();
      createCampaign.name = this.campaignDetailsFormGroup.value.name;
      createCampaign.description = this.campaignDetailsFormGroup.value.description;
      createCampaign.startDate = this.campaignDetailsFormGroup.value.startDate;
      createCampaign.endDate = this.campaignDetailsFormGroup.value.endDate;
      createCampaign.stampsObjective = this.campaignDetailsFormGroup.value.stampsObjective;
      createCampaign.campaignRewardName = this.campaignDetailsFormGroup.value.campaignRewardName;
      createCampaign.campaignRewardDescription = this.campaignDetailsFormGroup.value.campaignRewardDescription;
      createCampaign.voucherDuration = this.campaignDetailsFormGroup.value.voucherDuration.toString();
      createCampaign.recurrencePattern = this.campaignDetailsFormGroup.value.recurrencePattern;

      this.createCampaignComponentStore.createCampaign({ companyId: this.companyId, createCampaignModel: createCampaign });
    }
  }

  public onStampsSliderChanged($event) : void  {
    this.campaignDetailsFormGroup.patchValue({ stampsObjective: $event.value });
  }

  public onRecurrencePatternChanged($event): void {
    this.campaignDetailsFormGroup.patchValue({ recurrencePattern: $event });
  }

  public finishOnBoarding(): void {
    this.snackBar.open("Stamp card created successfully.", "Dismiss");
    this.router.navigate(['/companies/' + this.companyId + '/stamp-cards/' + this.campaignId]);
  }

  public fileChangeEvent(fileInput: any) : boolean {
    if (fileInput?.target?.files && fileInput?.target?.files[0]) {
      if (fileInput.target.files[0].size > this.max_size) {
        const errorMessage =
          'Maximum size allowed is ' + Math.floor(this.max_size / 1000) + 'Kb';
        this.snackBar.open(errorMessage, "Dismiss");
        return false;
      }

      if (!_.includes(this.allowed_types, fileInput.target.files[0].type)) {
        const errorMessage = 'Only Images are allowed ( JPG | PNG )';
        this.snackBar.open(errorMessage, "Dismiss");
        return false;
      }

      const uploadPhoto = new UploadPhotoModel();

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height > this.max_height || img_width > this.max_width) {
            const errorMessage =
              'Dimensions required ' +
              this.max_height +
              '*' +
              this.max_width +
              'px';
            this.snackBar.open(errorMessage, "Dismiss");
            return false;
          } 
          else if (img_height < this.min_height || img_width < this.min_width) {
            const errorMessage =
              'Dimensions required ' +
              this.min_height +
              '*' +
              this.min_width +
              'px';
            this.snackBar.open(errorMessage, "Dismiss");
            return false;
          }
          else {
            const imgBase64Path = e.target.result;
            uploadPhoto.fileContentBase64 = imgBase64Path.split(',')[1];
            this.campaignLogoComponentStore.uploadCampaignLogo({ companyId: this.companyId, campaignId: this.campaignId, uploadPhoto: uploadPhoto });
            return true;
          }
        };
      };

      const logo = fileInput.target.files[0];

      uploadPhoto.title = 'Campaign Logo';
      uploadPhoto.fileName = 'logo' + this._determineFileExtension(logo.type);

      reader.readAsDataURL(logo);
      return true;
    }
    return true;
  }

  public removeImage() : void {
    this.campaignLogoComponentStore.deleteCampaignLogo({ companyId: this.companyId, campaignId: this.campaignId });
  }

  public isXLarge(): boolean {
    return this._isXLarge;
  }

  public isLarge(): boolean {
    return this._isLarge;
  }

  public isMedium(): boolean {
    return this._isMedium;
  }

  public isSmall(): boolean {
    return this._isSmall;
  }

  public isXSmall(): boolean {
    return this._isXSmall;
  }

  private _determineFileExtension(fileType: string): string {
    switch (fileType) {
      case 'image/jpg':
      case 'image/jpeg':
        return '.jpg';
      case 'image/png':
        return '.png';
    }
    throw new Error('Unsupported file type:' + fileType);
  }

  private showSpinner() : void {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) : void {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
