<div class="container">
  <div [ngClass]="{
    'container-item-xlarge': isXLarge(),
    'container-item-large': isLarge(),
    'container-item-medium': isMedium(),
    'container-item-small': isSmall(),
    'container-item-xsmall': isXSmall()
  }">
    <h2 class="item-title">Planet VG Chefs</h2>
    <h3 class="item-subtitle">We are ready to help you and your team build the best vegan loyalty experience.</h3>
    <mat-list>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Feel free to book a demo.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">Ask us your questions.</div>
      </mat-list-item>
      <mat-list-item [ngClass]="{
        'list-item-xsmall': isXSmall()
      }">
        <span matListItemIcon>
          <mat-icon color="primary">eco</mat-icon>
        </span>
        <div matListItemTitle class="list-item-title">If you can't find a feature you're looking for, please let us
          know.</div>
      </mat-list-item>
    </mat-list>
  </div>

  <div [ngClass]="{
    'container-item-card-xlarge': isXLarge(),
    'container-item-card-large': isLarge(),
    'container-item-card-medium': isMedium(),
    'container-item-card-small': isSmall(),
    'container-item-card-xsmall': isXSmall()
  }">
    <mat-card appearance="outlined" class="column-card mat-component-background mat-elevation-z2">
      <mat-card-header>
        <mat-card-title-group>
          <mat-card-title>How may we help you?</mat-card-title>
        </mat-card-title-group>
      </mat-card-header>
      <div [ngClass]="{
        'container-product-banner-image-xlarge': isXLarge(),
        'container-product-banner-image-large': isLarge(),
        'container-product-banner-image-medium': isMedium(),
        'container-product-banner-image-small': isSmall(),
        'container-product-banner-image-xsmall': isXSmall()
      }">
        <img src="/assets/img/banners/xlarge/product-banner-help-you.webp"
          alt="we-are-ready-to-help-you-contact-us" />
      </div>
      <mat-card-actions class="container-card-actions">
        <span><a mat-raised-button href="https://meetings-eu1.hubspot.com/iskandar-prins" color="primary" class="topmenu-item" target="_blank" rel="noopener"><mat-icon>event</mat-icon>Book a Demo</a></span>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<ng-template #xSmallContentImage>
  <div *ngIf="isXSmall();">
    <img src="/assets/img/banners/xsmall/product-banner-take-order-xs.webp" alt="we-are-ready-to-help-you-contact-us" />
  </div>
</ng-template>