<div [ngClass]="{
    'container-xlarge': isXLarge(),
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">

    <h1>
        Meet the Planet VG Chefs
    </h1>

    <div [ngClass]="{
        'container-card-row-xlarge': isXLarge(),
        'container-card-row-large': isLarge(),
        'container-card-row-medium': isMedium(),
        'container-card-row-small': isSmall(),
        'container-card-row-xsmall': isXSmall()
      }">

        <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
            <img mat-card-image src="/assets/img/profile_nicoya_habes.png" alt="Nicoya-Habes" />
            <mat-card-content>
                <div class="container-profile-content">
                    <p>Nicoya Habes<br/><em>Software Development</em>
                    </p>
                    <p><q>Veganism is a journey that we can experience together, and with Planet VG 
                        we want to elevate and unite people on their path to make the world a better place.</q>
                    </p>
                    <p><q>At Almost Level 5, I have found my way to contribute to changing
                        the way we treat the animals and the planet.</q>
                    </p>
                </div>
            </mat-card-content>
        </mat-card>
        <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
            <img mat-card-image src="/assets/img/profile.jpg" alt="Iskandar-Prins" />
            <mat-card-content>
                <div class="container-profile-content">
                    <p>Iskandar Prins<br />
                        <em>Business & Software Development, Founder</em>
                    </p>
                    <p>
                        <q>With Planet VG we want to give vegan companies an ethical choice for building their eco- and animal-friendly community</q>
                    </p>
                    <p>
                        <q>We always strive for the best and together we can make this planet joyful and kind for everybody.</q>
                    </p>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>