import { Component, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailRegex, urlRegex } from '../../validators/validation-regex';
import { Store } from '@ngrx/store';
import { selectBusinessAccount } from 'src/app/stores/global/app.selectors';
import { BusinessAccountModel } from 'src/app/models/business-account-model';
import { Observable, Subject, filter, of, takeUntil } from 'rxjs';
import { UpdateCompanyComponentStore } from 'src/app/stores/components/company-edit/update-company.component-store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyLogoComponentStore } from 'src/app/stores/components/company-logo/company-logo.component-store';
import { UploadPhotoModel } from 'src/app/models/upload-photo-model';
import * as _ from 'lodash';
import { UpdateCompanyModel } from 'src/app/models/update-company-model';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SpinnerDialogComponent } from '../dialogs/spinner-dialog/spinner-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { ValidationException } from '../../exceptions/validation-exception';
import { CompanyNameVerifyService } from 'src/app/services/company-name-verify.service';
import { CompanyNameNotTakenValidator } from '../../validators/company-name-not-taken.validator';
import { CreateStoreLocationModel } from 'src/app/models/create-store-location-model';
import {
  CategoryBagelShop,
  CategoryBakery,
  CategoryBar,
  CategoryBarberShop,
  CategoryBodyPieringShop,
  CategoryCafeCoffeeAndTeaHouse,
  CategoryCafeteria,
  CategoryDryCleaner,
  CategoryFarmersMarket,
  CategoryFastFoodRestaurant,
  CategoryFitnessCenter,
  CategoryFoodStand,
  CategoryFoodTruck,
  CategoryFruitAndVegetableStore,
  CategoryGroceryStore,
  CategoryHairSalon,
  CategoryHealthFoodStore,
  CategoryHotel,
  CategoryIceCreamParlor,
  CategoryJuiceBar,
  CategoryMassageClinic,
  CategoryNailSalon,
  CategoryPetService,
  CategoryRestaurant,
  CategoryRetailStore,
  CategorySkinCareClinic,
  CategorySpa,
  CategoryTattooParlor,
  CategoryYogaStudio,
  StoreLocationCategory,
} from 'src/app/models/store-location-category-model';
import { StoreLocationOption } from 'src/app/models/store-location-option-model';
import { StoreLocationType, TypeOther, TypeVegan, TypeVegetarian } from 'src/app/models/store-location-type-model';
import { MaximumActiveStoreLocationsExceededException } from '../../exceptions/maximum-active-store-locations-exceeded-exception';
import { CreateStoreLocationComponentStore } from 'src/app/stores/components/store-location-create/create-store-location.component-store';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

interface StoreLocationTypeViewModel {
  value: StoreLocationType,
  viewValue: string,
}

interface StoreLocationCategoryViewModel {
  value: StoreLocationCategory,
  viewValue: string,
}

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent {
  @Output()
  public onOnBoardingFinished = new EventEmitter();

  public readonly minNameLength: number = 1;
  public readonly maxNameLength: number = 32;
  public readonly minDescriptionLength: number = 3;
  public readonly maxDescriptionLength: number = 255;

  public readonly storeLocationMinNameLength: number = 1;
  public readonly storeLocationMaxNameLength: number = 32;
  public readonly storeLocationMinDescriptionLength: number = 3;
  public readonly storeLocationMaxDescriptionLength: number = 255;
  public readonly storeLocationMinAddressLineLength: number = 3;
  public readonly storeLocationMaxAddressLineLength: number = 128;
  public readonly storeLocationMinPostalCodeLength: number = 1;
  public readonly storeLocationMaxPostalCodeLength: number = 32;
  public readonly storeLocationMinCityLength: number = 3;
  public readonly storeLocationMaxCityLength: number = 128;
  public readonly storeLocationMaxCountryLength: number = 32;
  public readonly storeLocationMinPhoneNumberLength: number = 8;
  public readonly storeLocationMaxPhoneNumberLength: number = 32;

  public readonly storeLocationTypes: StoreLocationTypeViewModel[] = [
    { value: StoreLocationType.VEGAN, viewValue: TypeVegan },
    { value: StoreLocationType.VEGETARIAN, viewValue: TypeVegetarian },
    { value: StoreLocationType.OTHER, viewValue: TypeOther },
  ];

  public readonly storeLocationCategories: StoreLocationCategoryViewModel[] = [
    { value: StoreLocationCategory.BAGEL_SHOP, viewValue: CategoryBagelShop },
    { value: StoreLocationCategory.BAKERY, viewValue: CategoryBakery },
    { value: StoreLocationCategory.BAR, viewValue: CategoryBar },
    { value: StoreLocationCategory.BARBER_SHOP, viewValue: CategoryBarberShop },
    { value: StoreLocationCategory.BODY_PIERCING_SHOP, viewValue: CategoryBodyPieringShop },
    { value: StoreLocationCategory.CAFE_COFFEE_AND_TEA_HOUSE, viewValue: CategoryCafeCoffeeAndTeaHouse },
    { value: StoreLocationCategory.CAFETERIA, viewValue: CategoryCafeteria },
    { value: StoreLocationCategory.DRY_CLEANER, viewValue: CategoryDryCleaner },
    { value: StoreLocationCategory.JUICE_BAR, viewValue: CategoryJuiceBar },
    { value: StoreLocationCategory.FARMERS_MARKET, viewValue: CategoryFarmersMarket },
    { value: StoreLocationCategory.FAST_FOOD_RESTAURANT, viewValue: CategoryFastFoodRestaurant },
    { value: StoreLocationCategory.FITNESS_CENTER, viewValue: CategoryFitnessCenter },
    { value: StoreLocationCategory.FOOD_STAND, viewValue: CategoryFoodStand },
    { value: StoreLocationCategory.FOOD_TRUCK, viewValue: CategoryFoodTruck },
    { value: StoreLocationCategory.FRUIT_AND_VEGETABLE_STORE, viewValue: CategoryFruitAndVegetableStore },
    { value: StoreLocationCategory.GROCERY_STORE, viewValue: CategoryGroceryStore },
    { value: StoreLocationCategory.HAIR_SALON, viewValue: CategoryHairSalon },
    { value: StoreLocationCategory.HEALTH_FOOD_STORE, viewValue: CategoryHealthFoodStore },
    { value: StoreLocationCategory.HOTEL, viewValue: CategoryHotel },
    { value: StoreLocationCategory.ICE_CREAM_PARLOR, viewValue: CategoryIceCreamParlor },
    { value: StoreLocationCategory.MASSAGE_CLINIC, viewValue: CategoryMassageClinic },
    { value: StoreLocationCategory.NAIL_SALON, viewValue: CategoryNailSalon },
    { value: StoreLocationCategory.PET_SERVICE, viewValue: CategoryPetService },
    { value: StoreLocationCategory.RESTAURANT, viewValue: CategoryRestaurant },
    { value: StoreLocationCategory.RETAIL_STORE, viewValue: CategoryRetailStore },
    { value: StoreLocationCategory.SKIN_CARE_CLINIC, viewValue: CategorySkinCareClinic },
    { value: StoreLocationCategory.SPA, viewValue: CategorySpa },
    { value: StoreLocationCategory.TATTOO_PARLOR, viewValue: CategoryTattooParlor },
    { value: StoreLocationCategory.YOGA_STUDIO, viewValue: CategoryYogaStudio },
  ];

  public readonly placeholderUrl = '/assets/img/placeholder.png';
  public businessAccount$: Observable<BusinessAccountModel> = this.store.select(selectBusinessAccount);
  public companyLogoUrl$: Observable<string>;
  public isImageSaved$: Observable<boolean> = of(false);
  public companyDetailsFormGroup: FormGroup;
  public companyLogoFormGroup: FormGroup;
  public storeLocationDetailsFormGroup: FormGroup;

  private readonly ngUnsubscribeState = new Subject<void>();
  private readonly max_size = 538 * 1024;
  private readonly allowed_types = ['image/png', 'image/jpeg'];
  private readonly min_height = 512;
  private readonly min_width = 512;
  private readonly max_height = 512;
  private readonly max_width = 512;
  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;
  private _isXLarge: boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  private companyId: string;

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly store: Store<any>,
    private readonly updateCompanyStore: UpdateCompanyComponentStore,
    private readonly companyLogoComponentStore: CompanyLogoComponentStore,
    private readonly createStoreLocationComponentStore: CreateStoreLocationComponentStore,
    private readonly snackBar: MatSnackBar,
    private readonly spinnerService: SpinnerService,
    private readonly companyNameVerifyService: CompanyNameVerifyService,
    private readonly responsive: BreakpointObserver,
  ) { }

  public ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isXLarge = false;
        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Large]) {
          this._isLarge = true;
        }
        else {
          this._isXLarge = true;
        }
      }
    );

    this.companyDetailsFormGroup = this._formBuilder.group({
      id: ['', Validators.required],
      companyName: ['', [
        Validators.required,
        Validators.minLength(this.minNameLength),
        Validators.maxLength(this.maxNameLength)],
        [CompanyNameNotTakenValidator.createValidator(this.companyNameVerifyService)]
      ],
      description: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      website: ['', [Validators.required, Validators.pattern(urlRegex)]],
      companyEmail: ['', [Validators.required, Validators.pattern(emailRegex)]]
    });

    this.companyLogoFormGroup = this._formBuilder.group({
      uploadedLogo: ['', Validators.required]
    });

    this.storeLocationDetailsFormGroup = this._formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(this.storeLocationMinNameLength), Validators.maxLength(this.storeLocationMaxNameLength)]],
      description: ['', [Validators.required, Validators.minLength(this.storeLocationMinDescriptionLength), Validators.maxLength(this.storeLocationMaxDescriptionLength)]],
      addressLine1: ['', [Validators.required, Validators.minLength(this.storeLocationMinAddressLineLength), Validators.maxLength(this.storeLocationMaxAddressLineLength)]],
      addressLine2: ['', [Validators.maxLength(this.storeLocationMaxAddressLineLength)]],
      postalCode: ['', [Validators.required, Validators.minLength(this.storeLocationMinPostalCodeLength), Validators.maxLength(this.storeLocationMaxPostalCodeLength)]],
      city: ['', [Validators.required, Validators.minLength(this.storeLocationMinCityLength), Validators.maxLength(this.storeLocationMaxCityLength)]],
      country: ['', [Validators.required, Validators.maxLength(this.storeLocationMaxCountryLength)]],
      phoneNumber: ['', [Validators.required, Validators.minLength(this.storeLocationMinPhoneNumberLength), Validators.maxLength(this.storeLocationMaxPhoneNumberLength)]],
      type: ['', [Validators.required]],
      category: ['', [Validators.required]],
      optionVegan: [''],
      optionVegetarian: [''],
      optionVeganOptions: [''],
      optionVegetarianOptions: [''],
      optionGlutenFreeOptions: [''],
      optionBioOrganic: [''],
      optionBioDynamic: [''],
      optionZeroWaste: [''],
      optionPlasticFree: [''],
      optionPalmOilFree: [''],
    });

    this.store.select(selectBusinessAccount).pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(
      businessAccount => {
        this.companyId = businessAccount.company.id;
        this.companyDetailsFormGroup.patchValue({ 'id': businessAccount.company.id });
      }
    );

    this.updateCompanyStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(
      (loaded) => {
        this.hideSpinner(loaded);
      }
    );

    this.updateCompanyStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(
      (errorMessage) => {
        if (errorMessage !== null && errorMessage !== undefined) {
          this.updateCompanyStore.setInitial();
          if (errorMessage instanceof ValidationException) {
            const validationViolationMessage = errorMessage.violations.map(v => v.message).join("\n");
            this.snackBar.open(validationViolationMessage, "Dismiss");
          }
          else {
            this.snackBar.open(errorMessage, "Dismiss");
          }
        }
      }
    );

    this.updateCompanyStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(
      _ => {
        this.snackBar.open("Company details saved successfully.", "Dismiss", {
          duration: 3000
        });
      }
    );

    this.companyLogoComponentStore.logo$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(
      logo => {
        if (logo !== null && logo !== undefined) {
          this.isImageSaved$ = of(true);
          this.companyLogoUrl$ = of(logo.url);
          this.companyLogoFormGroup.patchValue({ 'uploadedLogo': true });
          this.snackBar.open("Company logo saved successfully.", "Dismiss", {
            duration: 3000
          });
        }
        else {
          this.isImageSaved$ = of(false);
          this.companyLogoUrl$ = of(this.placeholderUrl);
          this.companyLogoFormGroup.patchValue({ 'uploadedLogo': '' });
        }
      }
    );

    this.createStoreLocationComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(
      (_) => {
        this.hideSpinner(true);
      }
    );

    this.createStoreLocationComponentStore.storeLocation$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(
      (_) => {
        this.snackBar.open("Store location saved successfully.", "Dismiss", {
          duration: 3000
        });
      }
    );

    this.createStoreLocationComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(
      error => {
        this.hideSpinner(true);
        if (error instanceof MaximumActiveStoreLocationsExceededException) {
          this.snackBar.open("Can't create a new store location. You have already reached the maximum amount your subscription plan provides.", "Dismiss");
        }
        else if (error instanceof ValidationException) {
          const validationViolationMessage = error.violations.map(v => v.field + ' ' + v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
      }
    );
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.company) {
      this.companyLogoComponentStore.getCompanyLogo({ companyId: this.companyId });
    }
  }

  public saveCompany(): void {
    if (this.companyDetailsFormGroup.valid) {
      this.showSpinner();
      const updateCompany = new UpdateCompanyModel();
      updateCompany.id = this.companyDetailsFormGroup.value.id;
      updateCompany.name = this.companyDetailsFormGroup.value.companyName;
      updateCompany.description = this.companyDetailsFormGroup.value.description;
      updateCompany.email = this.companyDetailsFormGroup.value.companyEmail;
      updateCompany.url = this.companyDetailsFormGroup.value.website;

      this.updateCompanyStore.updateCompany(updateCompany);
    }
  }

  public saveStoreLocation(): void {
    this.storeLocationDetailsFormGroup.markAllAsTouched();

    if (this.storeLocationDetailsFormGroup.valid) {
      this.showSpinner();

      const createStoreLocation = new CreateStoreLocationModel();
      createStoreLocation.name = this.storeLocationDetailsFormGroup.value.name;
      createStoreLocation.description = this.storeLocationDetailsFormGroup.value.description;
      createStoreLocation.addressLine1 = this.storeLocationDetailsFormGroup.value.addressLine1;
      createStoreLocation.addressLine2 = this.storeLocationDetailsFormGroup.value.addressLine2;
      createStoreLocation.postalCode = this.storeLocationDetailsFormGroup.value.postalCode;
      createStoreLocation.city = this.storeLocationDetailsFormGroup.value.city;
      createStoreLocation.country = this.storeLocationDetailsFormGroup.value.country;
      createStoreLocation.phoneNumber = this.storeLocationDetailsFormGroup.value.phoneNumber;
      createStoreLocation.storeLocationType = this.storeLocationDetailsFormGroup.value.type;
      createStoreLocation.storeLocationCategory = this.storeLocationDetailsFormGroup.value.category;
      createStoreLocation.storeLocationOptions = this._getFormGroupStoreLocationOptions();

      this.createStoreLocationComponentStore.createStoreLocation({ companyId: this.companyId, createStoreLocation: createStoreLocation });
    }
  }

  public finishOnBoarding(): void {
    this.onOnBoardingFinished.emit();
  }

  public fileChangeEvent(fileInput: any): boolean {
    if (fileInput?.target?.files && fileInput?.target?.files[0]) {
      if (fileInput.target.files[0].size > this.max_size) {
        const errorMessage =
          'Maximum size allowed is ' + Math.floor(this.max_size / 1000) + 'Kb';
        this.snackBar.open(errorMessage, "Dismiss");
        return false;
      }

      if (!_.includes(this.allowed_types, fileInput.target.files[0].type)) {
        const errorMessage = 'Only Images are allowed ( JPG | PNG )';
        this.snackBar.open(errorMessage, "Dismiss");
        return false;
      }

      const uploadPhoto = new UploadPhotoModel();

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height > this.max_height || img_width > this.max_width) {
            const errorMessage =
              'Dimensions required ' +
              this.max_height +
              '*' +
              this.max_width +
              'px';
            this.snackBar.open(errorMessage, "Dismiss");
            return false;
          } 
          else if (img_height < this.min_height || img_width < this.min_width) {
            const errorMessage =
              'Dimensions required ' +
              this.min_height +
              '*' +
              this.min_width +
              'px';
            this.snackBar.open(errorMessage, "Dismiss");
            return false;
          } else {
            const imgBase64Path = e.target.result;
            uploadPhoto.fileContentBase64 = imgBase64Path.split(',')[1];
            this.companyLogoComponentStore.uploadCompanyLogo({ companyId: this.companyId, uploadPhoto: uploadPhoto });

            return true;
          }
        };
      };

      const logo = fileInput.target.files[0];

      uploadPhoto.title = 'Company Logo';
      uploadPhoto.fileName = 'logo' + this._determineFileExtension(logo.type);

      reader.readAsDataURL(logo);
      return true;
    }
    return true;
  }

  public removeImage(): void {
    this.companyLogoComponentStore.deleteCompanyLogo({ companyId: this.companyId });
  }

  public isXLarge(): boolean {
    return this._isXLarge;
  }

  public isLarge(): boolean {
    return this._isLarge;
  }

  public isMedium(): boolean {
    return this._isMedium;
  }

  public isSmall(): boolean {
    return this._isSmall;
  }

  public isXSmall(): boolean {
    return this._isXSmall;
  }

  private _determineFileExtension(fileType: string): string {
    switch (fileType) {
      case 'image/jpg':
      case 'image/jpeg':
        return '.jpg';
      case 'image/png':
        return '.png';
    }
    throw new Error('Unsupported file type:' + fileType);
  }

  private showSpinner(): void {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean): void {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }

  private _getFormGroupStoreLocationOptions(): StoreLocationOption[] {
    let storeLocationOptions: Array<StoreLocationOption> = [];

    if (this.storeLocationDetailsFormGroup.value.optionVeganOptions) {
      storeLocationOptions.push(StoreLocationOption.VEGAN_OPTIONS);
    }
    if (this.storeLocationDetailsFormGroup.value.optionVegetarianOptions) {
      storeLocationOptions.push(StoreLocationOption.VEGETARIAN_OPTIONS);
    }
    if (this.storeLocationDetailsFormGroup.value.optionGlutenFreeOptions) {
      storeLocationOptions.push(StoreLocationOption.GLUTEN_FREE_OPTIONS);
    }
    if (this.storeLocationDetailsFormGroup.value.optionBioOrganic) {
      storeLocationOptions.push(StoreLocationOption.BIO_ORGANIC);
    }
    if (this.storeLocationDetailsFormGroup.value.optionBioDynamic) {
      storeLocationOptions.push(StoreLocationOption.BIO_DYNAMIC);
    }
    if (this.storeLocationDetailsFormGroup.value.optionZeroWaste) {
      storeLocationOptions.push(StoreLocationOption.ZERO_WASTE);
    }
    if (this.storeLocationDetailsFormGroup.value.optionPlasticFree) {
      storeLocationOptions.push(StoreLocationOption.PLASTIC_FREE);
    }
    if (this.storeLocationDetailsFormGroup.value.optionPalmOilFree) {
      storeLocationOptions.push(StoreLocationOption.PALM_OIL_FREE);
    }
    return storeLocationOptions;
  }
}
