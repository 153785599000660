import { Component } from '@angular/core';
import { ResponsiveBaseComponent } from '../responsive-base/responsive-base.component';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-help-next-steps-store-app',
  templateUrl: './help-next-steps-store-app.component.html',
  styleUrl: './help-next-steps-store-app.component.scss'
})
export class HelpNextStepsStoreAppComponent extends ResponsiveBaseComponent {
  constructor(responsive : BreakpointObserver){
    super(responsive);
  }
}
