import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject, takeUntil } from 'rxjs';
import { PasswordInformationDialogComponent } from 'src/app/core/components/dialogs/password-information-dialog/password-information-dialog.component';
import { SpinnerDialogComponent } from 'src/app/core/components/dialogs/spinner-dialog/spinner-dialog.component';
import { UserAlreadyExistsException } from 'src/app/core/exceptions/user-already-exists-exception';
import { AccountEmailNotTakenValidator } from 'src/app/core/validators/account-email-not-taken.validator';
import { MustMatch } from 'src/app/core/validators/must-match.validator';
import { RegexValidator } from 'src/app/core/validators/regex-validator';
import { emailRegex } from 'src/app/core/validators/validation-regex';
import { CreateUserModel } from 'src/app/models/create-user-model';
import { AccountEmailVerifyService } from 'src/app/services/account-email-verify.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { RegisterComponentStore } from 'src/app/stores/components/registration/register.component-store';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public registrationForm: UntypedFormGroup;

  public readonly maxNameLength: number = 35;
  public readonly maxEmailLength: number = 254;

  ngUnsubscribeState = new Subject<void>();
  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;

  private _isXLarge: boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly accountEmailVerifyService: AccountEmailVerifyService,
    private readonly registerComponentStore: RegisterComponentStore,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly spinnerService: SpinnerService,
    private readonly responsive: BreakpointObserver,
    private readonly passwordInformationDialog: MatDialog,
  ) { }

  get registrationContactDetailsForm() {
    return this.registrationForm.controls.registrationContactDetailsForm as UntypedFormGroup;
  }

  ngOnInit(): void {
    this.registrationForm = this.formBuilder.group({
      registrationContactDetailsForm: this.formBuilder.group({
        firstName: ['', [Validators.required, Validators.maxLength(this.maxNameLength)]],
        lastName: ['', [Validators.required, Validators.maxLength(this.maxNameLength)]],
        email: ['', [
          Validators.required,
          Validators.pattern(emailRegex),
          Validators.maxLength(this.maxEmailLength)],
          [AccountEmailNotTakenValidator.createValidator(this.accountEmailVerifyService)]
        ],
        password: ['', [
          Validators.required,
          RegexValidator.createValidator(/\d/, { hasNumber: true }),
          RegexValidator.createValidator(/[A-Z]/, { hasCapitalCase: true }),
          RegexValidator.createValidator(/[a-z]/, { hasSmallNumber: true }),
          RegexValidator.createValidator(/[^a-zA-Z\d]/, { hasSpecialCase: true }),
          Validators.minLength(16)
        ]
        ],
        confirmPassword: ['', Validators.required],
      }, {
        validator: MustMatch("password", "confirmPassword")
      })
    });

    this.registerComponentStore.success$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      if (result) {
        this.registerComponentStore.setInitial();
        this.snackBar.open("Successfully registered. Please sign in and pick the right plan for your business.", "Dismiss");
        this.router.navigate(['/registered']);
      }
    });

    this.registerComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        if (errorMessage instanceof UserAlreadyExistsException) {
          errorMessage = "Email already used.";
        }
        else {
          errorMessage = 'Something went wrong. Please try again.';
        }
        this.snackBar.open(errorMessage, "Dismiss");
      }
    });

    this.registerComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });

    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isXLarge = false;
        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else if (breakpoints[Breakpoints.Large]) {
          this._isLarge = true;
        }
        else {
          this._isXLarge = true;
        }
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  signIn() {
    this.oidcSecurityService.authorize();
  }

  onRegister(event: any) {
    if (this.registrationForm.valid) {
      this.showSpinner();

      var createUser = new CreateUserModel();
      createUser.firstName = this.registrationContactDetailsForm.value.firstName.trim();
      createUser.lastName = this.registrationContactDetailsForm.value.lastName.trim();
      createUser.email = this.registrationContactDetailsForm.value.email.trim();
      createUser.password = this.registrationContactDetailsForm.value.password.trim();
      createUser.isBusiness = true;

      this.registerComponentStore.createUser({ createUserModel: createUser });
    }
  }

  public onInformationClicked(): void {
    console.log("fdkjb")
    if (this.passwordInformationDialog) {
      this.passwordInformationDialog.open(PasswordInformationDialogComponent, null);
    }
    else {
      console.log("Blergh")
    }
  }

  isXLarge(): boolean {
    return this._isXLarge;
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean {
    return this._isSmall;
  }
  isXSmall(): boolean {
    return this._isXSmall;
  }

  private showSpinner() {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
