<div [ngClass]="{
    'container-xlarge': isXLarge(),
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">
    <div class="content-banner-row">
        <div [ngClass]="{
        'container-content-xlarge': isXLarge(),
        'container-content-large': isLarge(),
        'container-content-medium': isMedium(),
        'container-content-small': isSmall(),
        'container-content-xsmall': isXSmall()
      }">
            <div [ngClass]="{
          'content-xlarge': isXLarge(),
          'content-large': isLarge(),
          'content-medium': isMedium(),
          'content-small': isSmall(),
          'content-xsmall': isXSmall()
        }">
                <h3>{{ customerStorySummary?.readingTime }} read</h3>
                <h1><span class="primary-title">{{ customerStorySummary?.description }}</span></h1>
                <h2>
                    <span>Beesechurgers' vision is to empower people to enjoy delicious fast-food for a brighter world.
                        Their mission is to provide a convenient, sustainable, high-quality fast-food with a
                        positive customer experience.</span>
                </h2>
            </div>
        </div>
        <div [ngClass]="{
        'container-product-banner-image-xlarge': isXLarge(),
        'container-product-banner-image-large': isLarge(),
        'container-product-banner-image-medium': isMedium(),
        'container-product-banner-image-small': isSmall(),
        'container-product-banner-image-xsmall': isXSmall()
      }">
            <img src="{{ customerStorySummary?.storyImageUrl }}" alt="{{ customerStorySummary?.description }}">
        </div>
    </div>

    <div class="content-banner-row">
        <div [ngClass]="{
            'container-stampcard-image-xlarge': isXLarge(),
            'container-stampcard-image-large': isLarge(),
            'container-stampcard-image-medium': isMedium(),
            'container-stampcard-image-small': isSmall(),
            'container-stampcard-image-xsmall': isXSmall()
          }">
            <img src="/assets/img/partners/stories/beesechurgers-stampcard.png" alt="Beesechurgers' stamp card">
        </div>
        <div [ngClass]="{
        'content-questions-xlarge': isXLarge(),
        'content-questions-large': isLarge(),
        'content-questions-medium': isMedium(),
        'content-questions-small': isSmall(),
        'content-questions-xsmall': isXSmall()
      }">
            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>How did you come about a loyalty program?</strong></span>
                </div>
                <span><em>We needed to create a loyalty program to keep our regular customers excited about coming
                    back.</em></span>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>Many businesses choose a paper loyalty card. Why have you chosen a digital version?</strong></span>
                </div>
                <span><em>People tend to forget and misplace their paper stamp cards, but nowadays you rarely forget your
                    phone when you leave the house. A digital loyalty program enhances the customer experience and reduces waste.</em></span>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>Why is it important for you to reward your most loyal customers?</strong></span>
                </div>
                <span><em>We needed to show them we appreciate them for supporting our business. Having a solid customer
                    base is key to our success.</em></span>
            </div>
            
            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>While looking for solutions, what were the most important factors for you?</strong></span>
                </div>
                <span><em>We wanted to create a positive customer experience when rewarding our loyal customers. We needed a solution
                    that was easy to use and aligned with our values.</em></span>
            </div>
            
            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>You chose Planet VG. What attracted you to use our solution?</strong></span>
                </div>
                <span><em>Planet VG has a nice design, is easy to use and we love their cool branding. And most important, they share our vegan
                    values.</em></span>
            </div>
            
            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>Can you tell us more about your loyalty program? What do you offer to your customers and why?</strong></span>
                </div>
                <span><em><p>As we started out with paper loyalty cards, our loyal customers tended to forget or lose their stamp
                    cards. This created a somewhat
                    sub-optimal customer experience, because we wanted to make sure that we can appreciate our
                    customers by rewarding them everytime they visited our restaurant. 
                    </p><p>When we started out with Planet VG we migrated
                    our paper stamp cards to the digital stamps. The process was really easy. We asked the customer
                    to onboard on Planet VG while they were waiting for their order.
                    During checkout we swapped the paper stampcard for the digital one.</p>
                <p>We created the following digital loyalty program. After collecting 10 stamps, they get a discount on the 11th visit.
                A thank you for their continuous support.</p></em></span>
            </div>
            
            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>Almost there! Would you recommend Planet VG to other businesses you might know?</strong></span>
                </div>
                <span><em>Definitely!</em></span>
            </div>
        </div>
    </div>
</div>
<app-bottom-menu></app-bottom-menu>