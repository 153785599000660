<div [ngClass]="{
    'container-xlarge': isXLarge(),
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">
    <div class="container-content">
        <div class="container-content-banner">
            <div class="container-menu">
                <h1>How our clients grew sustainably with vegan loyalty</h1>

                <h2>
                    What's on the menu?
                </h2>
                <h3>
                    Have a look at how our clients engaged customers, drove business results, and
                    brought those who matter most to them closer to their business
                    with the Planet VG loyalty program.
                </h3>
            </div>
        </div>

        <div [ngClass]="{
            'container-stories-steps-xlarge': isXLarge(),
            'container-stories-steps-large': isLarge(),
            'container-stories-steps-medium': isMedium(),
            'container-stories-steps-small': isSmall(),
            'container-stories-steps-xsmall': isXSmall()
          }">
            <div [ngClass]="{
            'container-stories-step-xlarge': isXLarge(),
            'container-stories-step-large': isLarge(),
            'container-stories-step-medium': isMedium(),
            'container-stories-step-small': isSmall(),
            'container-stories-step-xsmall': isXSmall()
            }" *ngFor="let customerStorySummary of getCustomerStorySummaries()">
                <app-customer-story-card [customerStorySummary]="customerStorySummary"></app-customer-story-card>
            </div>
        </div>
    </div>
</div>
<app-bottom-menu></app-bottom-menu>