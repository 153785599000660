<div [ngClass]="{
    'container-xlarge': isXLarge(),
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">
    <div class="content-banner-row">
        <div [ngClass]="{
        'container-content-xlarge': isXLarge(),
        'container-content-large': isLarge(),
        'container-content-medium': isMedium(),
        'container-content-small': isSmall(),
        'container-content-xsmall': isXSmall()
      }">
            <div [ngClass]="{
          'content-xlarge': isXLarge(),
          'content-large': isLarge(),
          'content-medium': isMedium(),
          'content-small': isSmall(),
          'content-xsmall': isXSmall()
        }">
                <h3>{{ customerStorySummary?.readingTime }} read</h3>
                <h1><span class="primary-title">{{ customerStorySummary?.description }}</span></h1>
                <h2>
                    <span>Vegan Deli Cioso wants to create a space where everyone can enjoy high-quality, plant-based
                        sandwiches that don’t just taste good but feel good too.</span>
                </h2>
            </div>
        </div>
        <div [ngClass]="{
        'container-product-banner-image-xlarge': isXLarge(),
        'container-product-banner-image-large': isLarge(),
        'container-product-banner-image-medium': isMedium(),
        'container-product-banner-image-small': isSmall(),
        'container-product-banner-image-xsmall': isXSmall()
      }">
            <img src="{{ customerStorySummary?.storyImageUrl }}" alt="{{ customerStorySummary?.description }}">
        </div>
    </div>

    <div class="content-banner-row">
        <div [ngClass]="{
            'container-stampcard-image-xlarge': isXLarge(),
            'container-stampcard-image-large': isLarge(),
            'container-stampcard-image-medium': isMedium(),
            'container-stampcard-image-small': isSmall(),
            'container-stampcard-image-xsmall': isXSmall()
          }">
            <img src="/assets/img/partners/stories/vegan-deli-cioso-stampcard.png" alt="Vegan Deli Cioso's stamp card">
        </div>
        <div [ngClass]="{
        'content-questions-xlarge': isXLarge(),
        'content-questions-large': isLarge(),
        'content-questions-medium': isMedium(),
        'content-questions-small': isSmall(),
        'content-questions-xsmall': isXSmall()
      }">
            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>How did you come about a loyalty program?</strong></span>
                </div>
                <span><em>We are a new vegan business and we wanted to start building a loyal customer base from the
                        beginning.</em></span>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>Many businesses choose a paper loyalty card. Why have you chosen a digital
                            version?</strong></span>
                </div>
                <span><em>We didn’t have a loyalty program at all, to start a digital one would be innovative for us.
                    </em></span>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>Why is it important for you to reward your most loyal customers?</strong></span>
                </div>
                <span><em>We always knew that to build a strong customer base, we needed to show appreciation to our
                        customers.</em></span>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>While looking for solutions, what were the most important factors for
                            you?</strong></span>
                </div>
                <span><em>Accessibility. The digital stamp cards are always accessible and the Planet VG Store App is
                        easy to use.</em></span>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>You chose Planet VG. What attracted you to use our solution?</strong></span>
                </div>
                <span><em>Vegan only app!</em></span>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>Can you tell us more about your loyalty program? What do you offer to your customers
                            and why?</strong></span>
                </div>
                <span><em>
                        <p>We created the following loyalty program where customers get a discounted menu combination. So that they can try all different parts of our menu for a
                            discounted price.
                        </p>
                    </em></span>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>Almost there! Would you recommend Planet VG to other businesses you might
                            know?</strong></span>
                </div>
                <span><em>Of course!</em></span>
            </div>
        </div>
    </div>
</div>
<app-bottom-menu></app-bottom-menu>