import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter, Observable, of, Subject, takeUntil } from 'rxjs';
import { SubscriptionPlanModel } from 'src/app/models/subscription-plan-model';
import { selectBusinessAccount, selectHasSubscriptionPlanOrder } from 'src/app/stores/global/app.selectors';
import { subscriptionPlansGetAction } from 'src/app/stores/subscriptionplans/subscriptionplans.actions';
import { SUBSCRIPTIONPLANS_GET_REDUCER_KEY } from 'src/app/stores/subscriptionplans/subscriptionplans.reducer';
import { SubscriptionPlansState } from 'src/app/stores/subscriptionplans/subscriptionplans.state';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  selectedRecurringType: string = "Monthly";
  recurringTypes: string[] = ['Monthly', 'Annually'];
  numberOfStores : number = 1;

  ngUnsubscribeState = new Subject<void>();

  subscriptionPlans$: Observable<SubscriptionPlanModel[]>;
  subscriptionPlansState$: Observable<SubscriptionPlansState> = this.store.pipe(select(SUBSCRIPTIONPLANS_GET_REDUCER_KEY));

  hasBusinessAccount$: Observable<boolean>;

  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  constructor(
    private readonly store: Store<any>, 
    private readonly router: Router, 
    private readonly responsive: BreakpointObserver,
  ) {
    // Do nothing
  }

  ngOnInit(): void {
    this.store.dispatch(subscriptionPlansGetAction());
    this.subscriptionPlansState$.subscribe(subscriptionPlansState =>
      this.subscriptionPlans$ = of(subscriptionPlansState.subscriptionPlans)
    );

    this.store.select(selectBusinessAccount).pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe((businessAccount) => {
      this.hasBusinessAccount$ = of(true);
    });

    this.store.select(selectHasSubscriptionPlanOrder).pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(hasSubscriptionPlanOrder => {
      this.router.navigate(['/dashboard']);
    });

    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else {
          this._isLarge = true;
        }
      }
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  public onNumberOfStoresChanged(event : any) : void {
    this.numberOfStores = event.target.value;
    console.log(this.numberOfStores);
  }

  isLarge(): boolean {
    return this._isLarge;
  }

  isMedium(): boolean {
    return this._isMedium;
  }
  isSmall(): boolean {
    return this._isSmall;
  }
  isXSmall(): boolean {
    return this._isXSmall;
  }
}
