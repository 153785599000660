import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { catchError, Observable, switchMap, tap } from "rxjs";
import { CompanySummariesService } from "src/app/services/company-summaries.service";
import { StatsOverallSummaryState } from "./stats-overall-summary.state";
import { CompanyOverallSummaryModel } from "src/app/models/company-overall-summary-model";

const initialState: StatsOverallSummaryState = {
    loading: false,
    loaded: false,
    success: false,
    errorMessage: null,
    companyOverallSummary : null,
}

@Injectable({
    providedIn: 'root'
})
export class StatsOverallSummaryComponentStore extends ComponentStore<StatsOverallSummaryState> {
    constructor(private readonly companySummariesServices: CompanySummariesService) {
        super(initialState);
    }

    readonly companyOverallSummary$: Observable<CompanyOverallSummaryModel> = this.select(state => state.companyOverallSummary);
    readonly loading$: Observable<boolean> = this.select(state => state.loading);
    readonly loaded$: Observable<boolean> = this.select(state => state.loaded);
    readonly success$: Observable<boolean> = this.select(state => state.success);
    readonly errorMessage$: Observable<any> = this.select(state => state.errorMessage);

    readonly viewModel$ = this.select(
        this.companyOverallSummary$,
        this.loaded$,
        this.loading$,
        this.success$,
        this.errorMessage$,
        (companyOverallSummary, loaded, loading, success, errorMessage) => ({
            companyOverallSummary, loaded, loading, success, errorMessage
        })
    );

    readonly setInitial = this.updater((_: StatsOverallSummaryState) => {
        return {
            ...initialState
        };
    });

    readonly setLoading = this.updater((state: StatsOverallSummaryState) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        };
    });

    readonly setLoaded = this.updater((state: StatsOverallSummaryState) => {
        return {
            ...state,
            loading: false,
            loaded: true,
        };
    });

    readonly updateStatsOverallSummaryState = this.updater((state: StatsOverallSummaryState, value: {
        companyOverallSummary: CompanyOverallSummaryModel
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: true,
            companyOverallSummary : value.companyOverallSummary
        };
    });

    readonly setError = this.updater((state: StatsOverallSummaryState, value: {
        errorMessage: string
    }) => {
        return {
            ...state,
            loading: false,
            loaded: true,
            success: false,
            errorMessage: value.errorMessage,
        };
    });

    readonly getCompanyOverallSummary = this.effect((params$: Observable<{ companyId: string }>) => {
        this.setLoading();
        return params$.pipe(
            switchMap((params => this.companySummariesServices.getCompanyOverallSummary(params.companyId).pipe(
                tap({
                    next: (companyOverallSummary) => {
                        this.updateStatsOverallSummaryState({ companyOverallSummary: companyOverallSummary });
                        this.setLoaded();
                    },
                    error: (e) => {
                        throw e;
                    }
                }),
                catchError(async (error) => {
                    this.setError({ errorMessage: error })
                    this.setLoaded();
                    this.setInitial();
                })
            ))
            )
        );
    });
}