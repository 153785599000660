import { Component } from '@angular/core';
import { ResponsiveBaseComponent } from '../responsive-base/responsive-base.component';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-team-bios',
  templateUrl: './team-bios.component.html',
  styleUrl: './team-bios.component.scss'
})
export class TeamBiosComponent extends ResponsiveBaseComponent{
  constructor(responsive : BreakpointObserver ) {
    super(responsive);
  }
}
