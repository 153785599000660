import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ResponsiveBaseComponent } from 'src/app/core/components/responsive-base/responsive-base.component';
import { CustomerStorySummaryModel } from 'src/app/models/viewmodels/customer-story-summary-model';

@Component({
  selector: 'app-customer-story-beesechurgers',
  templateUrl: './customer-story-beesechurgers.component.html',
  styleUrl: './customer-story-beesechurgers.component.scss'
})
export class CustomerStoryBeesechurgerComponent extends ResponsiveBaseComponent {
  public readonly customerStorySummary: CustomerStorySummaryModel =
    {
      routerLink: 'beesechurgers',
      title: 'Beesechurgers',
      description: 'From paper to digital stamp cards.',
      readingTime: '5 min',
      companyType: 'Vegan',
      companyCategory: 'Fast Food Restaurant',
      companyLogoUrl: '/assets/img/partners/beesechurgers.png',
      storyImageUrl: '/assets/img/partners/stories/beesechurgers2.jpg',
    };
    
  constructor(responsive: BreakpointObserver) {
    super(responsive);
  }
}