import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ResponsiveBaseComponent } from 'src/app/core/components/responsive-base/responsive-base.component';
import { CustomerStorySummaryModel } from 'src/app/models/viewmodels/customer-story-summary-model';

@Component({
  selector: 'app-customer-story-miss-nice-banana',
  templateUrl: './customer-story-miss-nice-banana.component.html',
  styleUrl: './customer-story-miss-nice-banana.component.scss'
})
export class CustomerStoryMissNiceBananaComponent extends ResponsiveBaseComponent {
  public readonly customerStorySummary: CustomerStorySummaryModel =
      {
        routerLink: 'miss-nice-banana',
        title: 'Miss Nice Banana',
        description: 'Creating real connections by rewarding loyal customers.',
        readingTime: '5 min',
        companyType: 'Vegan',
        companyCategory: 'Restaurant',
        companyLogoUrl: '/assets/img/partners/miss-nice-banana.png',
        storyImageUrl: '/assets/img/partners/stories/miss-nice-banana.jpg',
      };
  
      
  constructor(responsive : BreakpointObserver){
    super(responsive);
  }
}