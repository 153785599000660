<div [ngClass]="{
  'container-xlarge': isXLarge(),
  'container-large': isLarge(),
  'container-medium': isMedium(),
  'container-small': isSmall(),
  'container-xsmall': isXSmall()
}">
  <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2 card-pointer"
    [routerLink]="['/customer-stories', customerStorySummary?.routerLink]">
    <mat-card-header>
      <div mat-card-avatar class="container-company-logo"
        [ngStyle]="{'background-image': 'url(' + customerStorySummary?.companyLogoUrl! + ')'}">
      </div>
      <mat-card-title>
        {{ customerStorySummary.title }}
      </mat-card-title>
      <mat-card-subtitle>
        {{ customerStorySummary.companyType }} {{ customerStorySummary.companyCategory }}
      </mat-card-subtitle>
    </mat-card-header>

    <img mat-card-image src="{{ customerStorySummary.storyImageUrl }}"
      alt="Customer Story {{ customerStorySummary.title }}" class="container-card-image" />

    <mat-card-content class="container-card-content">
      <div class="content">
        <h1>
          {{ customerStorySummary.description }}
        </h1>
      </div>
    </mat-card-content>
    <mat-card-actions class="container-actions">
      <mat-icon>schedule</mat-icon>
      <span>
        {{ customerStorySummary.readingTime }} read
      </span>
    </mat-card-actions>
  </mat-card>
</div>