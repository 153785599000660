import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-registration-contact-details',
  templateUrl: './registration-contact-details.component.html',
  styleUrls: ['./registration-contact-details.component.scss']
})
export class RegistrationContactDetailsComponent implements OnInit {
  @Input()
  registrationDetailsFormGroup: UntypedFormGroup;

  @Input()
  maxNameLength : number;

  @Input()
  maxEmailLength : number;

  @Output()
  onRegisterPressed = new EventEmitter();

  @Output()
  onInformationClicked = new EventEmitter();

  public hidePassword : boolean = true;
  public hideConfirmPassword : boolean = true;
  
  constructor() { 
    // Do nothing
  }

  public ngOnInit(): void {
    // Do nothing
  }

  public register() : void {
    this.onRegisterPressed.emit(null);
  }

  public onInformationIconClicked() : void {
     this.onInformationClicked.emit(null);
  }

  public toggleHidePassword(){
    this.hidePassword = !this.hidePassword;
  }

  public toggleHideConfirmPassword(){
    this.hideConfirmPassword = !this.hideConfirmPassword;
  }
}
