import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-password-information-dialog',
  templateUrl: './password-information-dialog.component.html',
  styleUrl: './password-information-dialog.component.scss'
})
export class PasswordInformationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PasswordInformationDialogComponent>
  ) {
    // Do nothing
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
