<div *ngIf="onBoarded; else gettingStarted">
<mat-nav-list>
    <a mat-list-item [routerLink]="['/dashboard']" routerLinkActive="list-item-active" >Dashboard</a>
    <mat-divider></mat-divider>
    <a mat-list-item [routerLink]="['/company']" routerLinkActive="list-item-active">Company</a>
    <a mat-list-item [routerLink]="['/store-locations']" routerLinkActive="list-item-active">Store Locations</a>
    <mat-divider></mat-divider>
    <a mat-list-item [routerLink]="['/stamp-cards']" routerLinkActive="list-item-active">Stamp Cards</a>
    <a mat-list-item [routerLink]="['/promotions']" routerLinkActive="list-item-active">Promotions</a>
</mat-nav-list>
</div>

<ng-template #gettingStarted>
    <mat-nav-list>
        <a mat-list-item [routerLink]="['/dashboard']" routerLinkActive="list-item-active">Dashboard</a>
    </mat-nav-list>
</ng-template>