<div class="container">
  <div [ngClass]="{
    'container-content-xlarge': isXLarge(),
    'container-content-large': isLarge(),
    'container-content-medium': isMedium(),
    'container-content-small': isSmall(),
    'container-content-xsmall': isXSmall()
  }">
    <div [ngClass]="{
    'container-store-location-xlarge': isXLarge(),
    'container-store-location-large': isLarge(),
    'container-store-location-medium': isMedium(),
    'container-store-location-small': isSmall(),
    'container-store-location-xsmall': isXSmall()
  }">
      <div *ngIf="readMode; else editMode">
        <app-store-location [storeLocation]="(viewModel$ | async)?.storeLocation" (onEditPressed)="onEdit($event)"
          (onDeletePressed)="onDelete($event)" (onActivatePressed)="onActivate($event)"
          (onDeactivatePressed)="onDeactivate($event)"></app-store-location>
      </div>
    </div>

    <div [ngClass]="{
      'container-store-user-xlarge': isXLarge(),
      'container-store-user-large': isLarge(),
      'container-store-user-medium': isMedium(),
      'container-store-user-small': isSmall(),
      'container-store-user-xsmall': isXSmall()
    }" *ngIf="showStoreUser">
      <app-store-user-details (onGenerateNewPassword)="onGenerateNewPassword($event)"
        [storeUser]="(storeUserViewModel$ | async)?.storeUser"></app-store-user-details>
        <app-help-next-steps-store-app></app-help-next-steps-store-app>
    </div>
  </div>
</div>

<ng-template #editMode>
  <app-store-location-edit [minNameLength]="minNameLength" [maxNameLength]="maxNameLength"
    [minDescriptionLength]="minDescriptionLength" [maxDescriptionLength]="maxDescriptionLength"
    [maxAddressLineLength]="maxAddressLineLength" [minPostalCodeLength]="minPostalCodeLength"
    [maxPostalCodeLength]="maxPostalCodeLength" [minCityLength]="minCityLength" [maxCityLength]="maxCityLength"
    [minPhoneNumberLength]="minPhoneNumberLength" [maxPhoneNumberLength]="maxPhoneNumberLength"
    [storeLocation]="(viewModel$ | async)?.storeLocation"
    [storeLocationDetailsFormGroup]="storeLocationDetailsFormGroup" (onCancelPressed)="onCancel($event)"
    (onSavePressed)="onSave($event)"></app-store-location-edit>
</ng-template>