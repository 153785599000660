import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { Observable, Subject, filter, of, takeUntil } from 'rxjs';
import { BusinessAccountModel } from 'src/app/models/business-account-model';
import { CompanyCampaignSummaryModel } from 'src/app/models/company-campaign-summary-model';
import { CompanyPromotionSummaryModel } from 'src/app/models/company-promotion-summary-model';
import { CompanyStoreLocationSummaryModel } from 'src/app/models/company-store-location-summary-model';
import { StatsActiveCampaignChartsComponentStore } from 'src/app/stores/components/stats-active-campaign-charts/stats-active-campaign-charts.component-store';
import { StatsActivePromotionChartsComponentStore } from 'src/app/stores/components/stats-active-promotion-charts/stats-active-promotion-charts.component-store';
import { StatsOverallSummaryComponentStore } from 'src/app/stores/components/stats-overall-summary/stats-overall-summary.component-store';
import { StatsTotalStoreLocationsChartComponentStore } from 'src/app/stores/components/stats-total-store-locations-chart/stats-total-store-locations-chart.component-store';
import { selectBusinessAccount } from 'src/app/stores/global/app.selectors';

export interface DashboardOverviewViewModel {
  storelocations: number;
  maximumStoreLocations: number;
  promotions: number;
  maximumPromotions: number;
  stampCards: number;
  maximumStampCards: number;
}

const ELEMENT_DATA: DashboardOverviewViewModel[] = [
];

@Component({
  selector: 'app-dashboard-banner',
  templateUrl: './dashboard-banner.component.html',
  styleUrls: ['./dashboard-banner.component.scss']
})
export class DashboardBannerComponent {
  displayedColumns: string[] = ['storelocations', 'promotions', 'stampCards'];
  dataSource = ELEMENT_DATA;

  businessAccount$: Observable<BusinessAccountModel> = this.store.select(selectBusinessAccount);
  companyPromotionSummaries$: Observable<CompanyPromotionSummaryModel[]>;
  companyCampaignSummaries$: Observable<CompanyCampaignSummaryModel[]>;
  companyStoreLocationSummary$: Observable<CompanyStoreLocationSummaryModel>;
  ngUnsubscribeState = new Subject<void>();

  isLoading = false;

  constructor(
    private readonly store: Store<any>,
    private readonly statsActiveCampaignChartsComponentStore: StatsActiveCampaignChartsComponentStore,
    private readonly statsActivePromotionChartsComponentStore: StatsActivePromotionChartsComponentStore,
    private readonly statsTotalStoreLocationsComponentStore: StatsTotalStoreLocationsChartComponentStore,
    private readonly statsOverallSummaryComponentStore: StatsOverallSummaryComponentStore,
    private readonly snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    ELEMENT_DATA[0] = { storelocations: 0, maximumStoreLocations: 0, promotions: 0, maximumPromotions: 0, stampCards: 0, maximumStampCards: 0 };

    this.store.select(selectBusinessAccount).pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(
      businessAccount => {
        this.statsOverallSummaryComponentStore.getCompanyOverallSummary({ companyId: businessAccount.company.id });
        this.statsTotalStoreLocationsComponentStore.getCompanyStoreLocationSummary({ companyId: businessAccount.company.id });
        this.statsActivePromotionChartsComponentStore.getCompanyActivePromotionSummaries({ companyId: businessAccount.company.id });
        this.statsActiveCampaignChartsComponentStore.getCompanyActiveCampaignSummaries({ companyId: businessAccount.company.id });
      });

    this.statsOverallSummaryComponentStore.companyOverallSummary$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(
      companyCampaignSummary => {
        ELEMENT_DATA[0].maximumPromotions = companyCampaignSummary.maximumPromotions;
        ELEMENT_DATA[0].maximumStampCards = companyCampaignSummary.maximumCampaigns;
        ELEMENT_DATA[0].maximumStoreLocations = companyCampaignSummary.maximumStoreLocations;
      });


    this.statsTotalStoreLocationsComponentStore.companyStoreLocationSummary$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(companyStoreLocationSummary => {
      this.companyStoreLocationSummary$ = of(companyStoreLocationSummary);
      ELEMENT_DATA[0].storelocations = companyStoreLocationSummary.totalStoreLocations;
    });

    this.statsActivePromotionChartsComponentStore.companyPromotionSummaries$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(companyPromotionSummaries => {
      this.companyPromotionSummaries$ = of(companyPromotionSummaries);

      ELEMENT_DATA[0].promotions = companyPromotionSummaries.length;
    });

    this.statsActiveCampaignChartsComponentStore.companyCampaignSummaries$.pipe(takeUntil(this.ngUnsubscribeState), filter(result => Boolean(result))).subscribe(companyCampaignSummaries => {
      this.companyCampaignSummaries$ = of(companyCampaignSummaries);
      ELEMENT_DATA[0].stampCards = companyCampaignSummaries.length;
    });

    this.statsActivePromotionChartsComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.statsActivePromotionChartsComponentStore.setInitial();
        this.snackBar.open(errorMessage, "Dismiss");
      }
    });

    this.statsActiveCampaignChartsComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      if (errorMessage !== null && errorMessage !== undefined) {
        this.statsActiveCampaignChartsComponentStore.setInitial();
        this.snackBar.open(errorMessage, "Dismiss");
      }
    });
  }
}
