import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ResponsiveBaseComponent } from 'src/app/core/components/responsive-base/responsive-base.component';
import { CustomerStorySummaryModel } from 'src/app/models/viewmodels/customer-story-summary-model';

@Component({
  selector: 'app-customer-story-vegan-deli-cioso',
  templateUrl: './customer-story-vegan-deli-cioso.component.html',
  styleUrl: './customer-story-vegan-deli-cioso.component.scss'
})
export class CustomerStoryVeganDeliCiosoComponent extends ResponsiveBaseComponent {
  public readonly customerStorySummary: CustomerStorySummaryModel =
    {
      routerLink: 'vegan-deli-cioso',
      title: 'Vegan Deli Cioso',
      description: 'To build a strong customer base, we need to show appreciation.',
      readingTime: '3 min',
      companyType: 'Vegan',
      companyCategory: 'Cafe Coffee & Tea House',
      companyLogoUrl: '/assets/img/partners/vegan-deli-cioso.png',
      storyImageUrl: '/assets/img/partners/stories/vegan-deli-cioso.jpg',
    };

  constructor(responsive: BreakpointObserver) {
    super(responsive);
  }
}
