import { AbstractControl, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CompanyNameVerifyService } from "src/app/services/company-name-verify.service";

export class CompanyNameNotTakenValidator {
    static createValidator(companyNameVerifyService: CompanyNameVerifyService): AsyncValidatorFn {
        return (control: AbstractControl) : Observable<ValidationErrors | null> => { 
            if(!control.touched || !control.pristine){
                return of(null);
            }

            return companyNameVerifyService.checkCompanyNameTaken(control.value).pipe(
                map(result => {
                    if(result){
                        return { companyNameTaken: true };
                    }
                    else {
                        return null;
                    }
                }),
                catchError(() => of(null))
            );
        }
    }   
}