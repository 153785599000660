<div class="container">
  <div class="container-content">
    <div class="container-column-left">
      <app-registration-chefs-special></app-registration-chefs-special>
      <app-testimonials-logos-only-carousel></app-testimonials-logos-only-carousel>
    </div>
    <div [ngClass]="{
      'container-registration-xlarge': isXLarge(),
      'container-registration-large': isLarge(),
      'container-registration-medium': isMedium(),
      'container-registration-small': isSmall(),
      'container-registration-xsmall': isXSmall()
    }">
      <app-registration-contact-details [maxNameLength]="maxNameLength" [maxEmailLength]="maxEmailLength"
        [registrationDetailsFormGroup]="registrationContactDetailsForm"
        (onRegisterPressed)="onRegister($event)"
        (onInformationClicked)="onInformationClicked()"
        ></app-registration-contact-details>
    </div>
  </div>
  <app-registration-testimonial-banner></app-registration-testimonial-banner>
</div>
<app-bottom-menu></app-bottom-menu>