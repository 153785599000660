<div [ngClass]="{
        'container-next-steps-xlarge': isXLarge(),
        'container-next-steps-large': isLarge(),
        'container-next-steps-medium': isMedium(),
        'container-next-steps-small': isSmall(),
        'container-next-steps-xsmall': isXSmall()
    }">

    <mat-card class="container" class="mat-component-background mat-elevation-z2">
        <mat-card-header>
            <div mat-card-avatar><mat-icon>start</mat-icon></div>
            <mat-card-title>Setup Your Store </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div *ngIf="!isMedium() && !isSmall() && !isXSmall(); else verticalStepper">
                <mat-horizontal-stepper [linear]="false" #stepper class="mat-component-background">
                    <mat-step [completed]="false">
                        <ng-template matStepLabel>Download the Store App</ng-template>
                        <ng-container *ngTemplateOutlet="stepDownLoadStoreApp"></ng-container>
                        <div class="container-actions">
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>
                    <mat-step [completed]="false">
                        <ng-template matStepLabel>Sign in to the Store App</ng-template>
                        <ng-container *ngTemplateOutlet="stepLogInToStoreApp"></ng-container>
                        <div class="container-actions">
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>
                    <mat-step [completed]="false">
                        <ng-template matStepLabel>Issue Stamps</ng-template>
                        <ng-container *ngTemplateOutlet="stepIssueStamps"></ng-container>
                        <div class="container-actions">
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>
                    <mat-step [completed]="false">
                        <ng-template matStepLabel>Redeem Vouchers</ng-template>
                        <ng-container *ngTemplateOutlet="stepRedeemVouchers"></ng-container>
                        <div class="container-actions">
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button color="primary" matStepperNext routerLink="/dashboard">Done</button>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<ng-template #verticalStepper>
    <mat-vertical-stepper [linear]="false" #stepper class="mat-component-background">
        <mat-step [completed]="false">
            <ng-template matStepLabel>Download the Store App</ng-template>
            <ng-container *ngTemplateOutlet="stepDownLoadStoreApp"></ng-container>
            <div class="container-actions">
                <button mat-button matStepperNext>Next</button>
            </div>
        </mat-step>
        <mat-step [completed]="false">
            <ng-template matStepLabel>Sign in to the Store App</ng-template>
            <ng-container *ngTemplateOutlet="stepLogInToStoreApp"></ng-container>
            <div class="container-actions">
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext>Next</button>
            </div>
        </mat-step>
        <mat-step [completed]="false">
            <ng-template matStepLabel>Issue Stamps</ng-template>
            <ng-container *ngTemplateOutlet="stepIssueStamps"></ng-container>
            <div class="container-actions">
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button matStepperNext>Next</button>
            </div>
        </mat-step>
        <mat-step [completed]="false">
            <ng-template matStepLabel>Redeem Vouchers</ng-template>
            <ng-container *ngTemplateOutlet="stepRedeemVouchers"></ng-container>
            <div class="container-actions">
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button color="primary" matStepperNext routerLink="/dashboard">Done</button>
            </div>
        </mat-step>
    </mat-vertical-stepper>
</ng-template>

<ng-template #stepDownLoadStoreApp>
    <p>
        You can only <em>Issue Stamps</em> and <em>Redeem Vouchers</em> using the Planet VG Store App. The Store App icon looks like this: 
        <img src="/assets/img/app_icon_store.png" class="app-icon-store" alt="app-icon-store"/></p>
    <p>
        Download the Planet VG Store App from the following app stores:
    </p>
    <div [ngClass]="{
        'container-step-xlarge': isXLarge(),
        'container-step-large': isLarge(),
        'container-step-medium': isMedium(),
        'container-step-small': isSmall(),
        'container-step-xsmall': isXSmall()
    }">
        <span><a href="https://play.google.com/store/apps/details?id=com.almostlevel5.planetvg.loyalty.store"
                target="_blank" rel="noopener"><img class="app-store-icon" src="/assets/img/google-play-badge.png"
                    alt="Planet VG Store App - Google Play Store"></a></span>
        <span><a href="https://apps.apple.com/app/planet-vg-store/id6505109752" target="_blank" rel="noopener"><img
                    class="app-store-icon" src="/assets/img/apple-app-store-badge.png"
                    alt="Planet VG Store App - Apple App Store"></a></span>
    </div>
    <p><small>* Due to Apple's terms, the iPhone Planet VG Store app is not publicly visible in the Apple App Store. You can <strong>only</strong> download the iPhone app using this button.</small></p>
</ng-template>

<ng-template #stepLogInToStoreApp>
    <div [ngClass]="{
        'container-step-xlarge': isXLarge(),
        'container-step-large': isLarge(),
        'container-step-medium': isMedium(),
        'container-step-small': isSmall(),
        'container-step-xsmall': isXSmall()
    }">
        <ul>
            <li>Navigate to your store location.</li>
            <br/>
            <li>Find the store user account username in the <em>Planet VG Store User Account Details</em> section.</li>
            <li>The username starts with <strong><em>store-</em></strong>.</li>
            <br/>
            <li>Find your store user account password in your email or</li>
            <li>Generate a new password in case you lost it.</li>
            <br/>
            <li>Sign in to the Planet VG Store App.</li>
            <br/>
            <li>If you have multiple store locations, each store location has its own <em>Store User Account</em>. Repeat this for each mobile device for each store location.</li>
        </ul>
    </div>
</ng-template>

<ng-template #stepIssueStamps>
    <div [ngClass]="{
        'container-step-xlarge': isXLarge(),
        'container-step-large': isLarge(),
        'container-step-medium': isMedium(),
        'container-step-small': isSmall(),
        'container-step-xsmall': isXSmall()
    }">
    <ul>
        <li>Your customer:
            <ul>
                <li>Let your customer show you their loyalty card</li>
                <li>They need to press the <mat-icon class="icon-display">badge</mat-icon> button.</li>
            </ul>
        </li>
        <br/>
        <li>You or your employee:
            <ul>
                <li>Press the <strong>Issue Stamp</strong> button.</li>
                <li>Select the stamp card onto which you want to issue a stamp.</li>
                <li>Scan their loyalty card QR code.</li>        
            </ul>
        </li>
        <br/>
        <li>Your customer:
            <ul>
                <li>They now have received a stamp on their stamp card.</li>
            </ul>
        </li>
    </ul>
    </div>
</ng-template>

<ng-template #stepRedeemVouchers>
    <div [ngClass]="{
        'container-step-xlarge': isXLarge(),
        'container-step-large': isLarge(),
        'container-step-medium': isMedium(),
        'container-step-small': isSmall(),
        'container-step-xsmall': isXSmall()
    }">
    <ul>
        <li>Your customer:
            <ul>
                <li>Have your customer present their voucher.</li>
                <li>They can find their vouchers by pressing <mat-icon class="icon-display">menu</mat-icon></li>
                <li>And select <strong>Vouchers</strong>.</li>
                <li>From the list, select the correct voucher.</li>
                <li>Have your customer press the <strong><mat-icon class="icon-display">card_giftcard</mat-icon> Claim Reward</strong> button.</li>
            </ul>
        </li>
        <br/>
        <li>You or your employee:
            <ul>
                <li>Scan the voucher QR code.</li>        
                <li>You can now give them their reward.</li>
            </ul>
        </li>
    </ul>
    </div>
</ng-template>