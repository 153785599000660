import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ResponsiveBaseComponent } from 'src/app/core/components/responsive-base/responsive-base.component';

@Component({
  selector: 'app-help-next-steps',
  templateUrl: './help-next-steps.component.html',
  styleUrl: './help-next-steps.component.scss'
})
export class HelpNextStepsComponent extends ResponsiveBaseComponent {
  constructor(responsive: BreakpointObserver) {
    super(responsive);
  }
}
