<div [ngClass]="{
    'container-xlarge': isXLarge(),
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">

    <h1>
      These partners are growing sustainably with Planet VG
    </h1>

    <div [ngClass]="{
        'container-testimonals-xlarge': isXLarge(),
        'container-testimonals-large': isLarge(),
        'container-testimonals-medium': isMedium(),
        'container-testimonals-small': isSmall(),
        'container-testimonals-xsmall': isXSmall()
      }">
        <div *ngFor="let testimonial of getTestimonials()">
            <app-testimonial-card [testimonial]="testimonial"></app-testimonial-card>
        </div>
    </div>
</div>