<div class="container">
    <div [ngClass]="{
        'container-item-xlarge': isXLarge(),
        'container-item-large': isLarge(),
        'container-item-medium': isMedium(),
        'container-item-small': isSmall(),
        'container-item-xsmall': isXSmall()
      }">
        <h1>Register today for our openings offer!</h1>
        <h2>We just opened our doors, and we want to celebrate that with our Chef's Special.</h2>
        <h2>Our Chefs proudly want to give you a first tasting. Join Planet VG the <span class="primary-title">vegan</span> loyalty platform and receive your <span class="primary-title">first year</span> for <span class="primary-title">free</span>!
        </h2>
        <mat-list>
            <mat-list-item [ngClass]="{
            'list-item-xsmall': isXSmall()
          }">
                <span matListItemIcon>
                    <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Create digital loyalty stamp cards.</div>
            </mat-list-item>
            <mat-list-item [ngClass]="{
            'list-item-xsmall': isXSmall()
          }">
                <span matListItemIcon>
                    <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Engage with your customers.</div>
            </mat-list-item>
            <mat-list-item [ngClass]="{
            'list-item-xsmall': isXSmall()
          }">
                <span matListItemIcon>
                    <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Reward their loyalty.</div>
            </mat-list-item>
            <mat-list-item [ngClass]="{
                'list-item-xsmall': isXSmall()
              }">
                    <span matListItemIcon>
                        <mat-icon color="primary">eco</mat-icon>
                    </span>
                    <div matListItemTitle class="list-item-title">Drive your customers’ behavior.</div>
                </mat-list-item>
        </mat-list>
    </div>
</div>