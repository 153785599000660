<div class="container">
    <div [ngClass]="{
      'container-item-card-xlarge': isXLarge(),
      'container-item-card-large': isLarge(),
      'container-item-card-medium': isMedium(),
      'container-item-card-small': isSmall(),
      'container-item-card-xsmall': isXSmall()
    }">
        <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
            <mat-card-header>
                <mat-card-title>Chef's Special</mat-card-title>
                <mat-card-subtitle>Serving All Day Every Day</mat-card-subtitle>
            </mat-card-header>
            <div [ngClass]="{
          'container-product-banner-image-xlarge': isXLarge(),
          'container-product-banner-image-large': isLarge(),
          'container-product-banner-image-medium': isMedium(),
          'container-product-banner-image-small': isSmall(),
          'container-product-banner-image-xsmall': isXSmall()
        }">
                <img src="/assets/img/banners/xlarge/product-banner-chefs-special-opening-xl.webp"
                    alt="chefs-special-free-amuse-bouche" />
            </div>
            <mat-card-actions>
                <div *ngIf="!isAuthenticated">
                    <button mat-raised-button color="primary" routerLink="/register">
                        <mat-icon>app_registration</mat-icon>
                        Sign Up
                    </button>
                </div>
            </mat-card-actions>
        </mat-card>
    </div>

    <div [ngClass]="{
        'container-item-xlarge': isXLarge(),
        'container-item-large': isLarge(),
        'container-item-medium': isMedium(),
        'container-item-small': isSmall(),
        'container-item-xsmall': isXSmall()
      }">
        <h1>Register today for our openings offer!</h1>
        <h2>We just opened our doors, and we want to celebrate that with our Chef's Special.</h2>
        <h2>Our Chefs proudly want to give you a first tasting. Join Planet VG the <span
                class="primary-title">vegan</span> loyalty platform and receive your <span class="primary-title">first
                year</span> for <span class="primary-title">free</span>!
        </h2>
        <mat-list>
            <mat-list-item [ngClass]="{
            'list-item-xsmall': isXSmall()
          }">
                <span matListItemIcon>
                    <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Create digital loyalty stamp cards.</div>
            </mat-list-item>
            <mat-list-item [ngClass]="{
            'list-item-xsmall': isXSmall()
          }">
                <span matListItemIcon>
                    <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Engage with your customers.</div>
            </mat-list-item>
            <mat-list-item [ngClass]="{
            'list-item-xsmall': isXSmall()
          }">
                <span matListItemIcon>
                    <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Reward their loyalty.</div>
            </mat-list-item>
            <mat-list-item [ngClass]="{
                'list-item-xsmall': isXSmall()
              }">
                <span matListItemIcon>
                    <mat-icon color="primary">eco</mat-icon>
                </span>
                <div matListItemTitle class="list-item-title">Drive your customers’ behavior.</div>
            </mat-list-item>
        </mat-list>
    </div>
</div>