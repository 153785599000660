<div [ngClass]="{
    'container-xlarge': isXLarge(),
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">
    <mat-card appearance="outlined" class="mat-component-background mat-elevation-z2">
        <mat-card-header>
            <mat-card-title>Next Steps</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="container-content">
                <ul>
                    <li>Download the Store App.</li>
                    <li>Log in using the Store User Account.</li>
                    <li>Lost your password? You can always generate <mat-icon
                            class="icon-display">lock_reset</mat-icon>a new one.</li>
                </ul>
            </div>
        </mat-card-content>
        <mat-card-actions [ngClass]="{
            'container-actions-xlarge': isXLarge(),
            'container-actions-large': isLarge(),
            'container-actions-medium': isMedium(),
            'container-actions-small': isSmall(),
            'container-actions-xsmall': isXSmall()
          }">
            <!-- App Store Downloads -->
            <span><a href="https://play.google.com/store/apps/details?id=com.almostlevel5.planetvg.loyalty.store"
                    target="_blank" rel="noopener"><img class="app-store-icon" src="/assets/img/google-play-badge.png"
                        alt="Planet VG Store App - Google Play Store"></a></span>
            <span><a href="https://apps.apple.com/app/planet-vg-store/id6505109752" target="_blank" rel="noopener"><img
                        class="app-store-icon" src="/assets/img/apple-app-store-badge.png"
                        alt="Planet VG Store App - Apple App Store"></a></span>
        </mat-card-actions>
    </mat-card>
</div>
