import { Component, Input } from '@angular/core';
import { ResponsiveBaseComponent } from '../responsive-base/responsive-base.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CustomerStorySummaryModel } from 'src/app/models/viewmodels/customer-story-summary-model';

@Component({
  selector: 'app-customer-story-card',
  templateUrl: './customer-story-card.component.html',
  styleUrl: './customer-story-card.component.scss'
})
export class CustomerStoryCardComponent extends ResponsiveBaseComponent {
  @Input()
  customerStorySummary: CustomerStorySummaryModel;

  constructor(responsive: BreakpointObserver){
    super(responsive);
  }
}
