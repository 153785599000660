import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { EndDateAfterStartDate } from '../../validators/start-end-date.validator';
import { CreatePromotionModel } from 'src/app/models/create-promotion-model';
import { CreatePromotionComponentStore } from 'src/app/stores/components/promotion-create/create-promotion.component-store';
import { ValidationException } from '../../exceptions/validation-exception';
import { MaximumActivePromotionsExceededException } from '../../exceptions/maximum-active-promotions-exceeded-exception';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerDialogComponent } from '../dialogs/spinner-dialog/spinner-dialog.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import { PromotionLogoComponentStore } from 'src/app/stores/components/promotion-logo/promotion-logo.component-store';
import { UploadPhotoModel } from 'src/app/models/upload-photo-model';
import * as _ from 'lodash';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-promotion-create',
  templateUrl: './promotion-create.component.html',
  styleUrls: ['./promotion-create.component.scss']
})
export class PromotionCreateComponent implements OnInit {
  @Input()
  public promotionDetailsFormGroup: UntypedFormGroup;

  @Input()
  public promotionLogoFormGroup: FormGroup;

  @Output()
  public onCancelPressed = new EventEmitter();

  @Output()
  public onSavePressed = new EventEmitter();

  public readonly placeholderUrl = '/assets/img/placeholder.png';
  public readonly minNameLength: number = 3;
  public readonly maxNameLength: number = 32;
  public readonly minDescriptionLength: number = 3;
  public readonly maxDescriptionLength: number = 255;
  public isImageSaved$: Observable<boolean> = of(false);
  public promotionLogoUrl$: Observable<string>;

  private readonly ngUnsubscribeState = new Subject<void>();
  private readonly max_size = 538 * 1024;
  private readonly allowed_types = ['image/png', 'image/jpeg'];
  private readonly min_height = 512;
  private readonly min_width = 512;
  private readonly max_height = 512;
  private readonly max_width = 512;
  private loadingSpinnerDialogRef: MatDialogRef<SpinnerDialogComponent>;
  private _isXLarge: boolean;
  private _isLarge: boolean
  private _isMedium: boolean;
  private _isSmall: boolean;
  private _isXSmall: boolean;

  private companyId: string;
  private promotionId: number;

  constructor(private formBuilder: UntypedFormBuilder,
    private location: Location,
    private createPromotionComponentStore: CreatePromotionComponentStore,
    private readonly promotionLogoComponentStore: PromotionLogoComponentStore,
    private route: ActivatedRoute,
    private router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly spinnerService: SpinnerService,
    private readonly responsive: BreakpointObserver,
  ) { }

  public ngOnInit(): void {
    this.responsive.observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe(
      result => {
        const breakpoints = result.breakpoints;

        this._isXLarge = false;
        this._isLarge = false;
        this._isMedium = false;
        this._isSmall = false;
        this._isXSmall = false;

        if (breakpoints[Breakpoints.XSmall]) {
          this._isXSmall = true;
        }
        else if (breakpoints[Breakpoints.Small]) {
          this._isSmall = true;
        }
        else if (breakpoints[Breakpoints.Medium]) {
          this._isMedium = true;
        }
        else if (breakpoints[Breakpoints.Large]) {
          this._isLarge = true;
        }
        else {
          this._isXLarge = true;
        }
      }
    );

    this.route.params.subscribe(params => {
      this.companyId = params.companyId;
      this.promotionLogoComponentStore.reset({});
    });

    let startDate = new Date();
    let endDate = new Date();
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    endDate.setDate(endDate.getDate() + 3);


    let startDateVoucher = new Date();
    let endDateVoucher = new Date();
    startDateVoucher = new Date(startDateVoucher.getFullYear(), startDateVoucher.getMonth(), startDateVoucher.getDate());
    startDateVoucher.setDate(endDate.getDate() + 1)
    endDateVoucher = new Date(endDateVoucher.getFullYear(), endDateVoucher.getMonth(), endDateVoucher.getDate());
    endDateVoucher.setDate(startDateVoucher.getDate() + 1);

    this.promotionDetailsFormGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(this.minNameLength), Validators.maxLength(this.maxNameLength)]],
      description: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      startDate: [startDate, Validators.required],
      endDate: [endDate, Validators.required],
      startDateVoucher: [startDateVoucher, Validators.required],
      endDateVoucher: [endDateVoucher, Validators.required],
      promotionRewardName: ['', [Validators.required, Validators.minLength(this.minNameLength), Validators.maxLength(this.maxNameLength)]],
      promotionRewardDescription: ['', [Validators.required, Validators.minLength(this.minDescriptionLength), Validators.maxLength(this.maxDescriptionLength)]],
      recurrencePattern: [''],
    }, {
      validator: [EndDateAfterStartDate('startDate', 'endDate'), EndDateAfterStartDate('startDateVoucher', 'endDateVoucher')],
    });

    this.promotionLogoFormGroup = this.formBuilder.group({
      uploadedLogo: ['', Validators.required]
    });

    this.createPromotionComponentStore.promotion$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((result) => {
      this.hideSpinner(true);
      if (result) {
        this.promotionId = result.id;
        this.snackBar.open("Promotion saved successfully.", "Dismiss", {
          duration: 3000
        });
      }
    });

    this.createPromotionComponentStore.loaded$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((loaded) => {
      this.hideSpinner(loaded);
    });

    this.createPromotionComponentStore.errorMessage$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe((errorMessage) => {
      this.hideSpinner(true);
      if (errorMessage !== null && errorMessage !== undefined) {
        if (errorMessage instanceof ValidationException) {
          const validationViolationMessage = errorMessage.violations.map(v => v.field + ' ' + v.message).join("\n");
          this.snackBar.open(validationViolationMessage, "Dismiss");
        }
        else if (errorMessage instanceof MaximumActivePromotionsExceededException) {
          this.snackBar.open("Can't create a new promotion. You have already reached the maximum amount your subscription plan provides.", "Dismiss");
        }
        else {
          this.snackBar.open(errorMessage, "Dismiss");
        }
      }
    });

    this.promotionLogoComponentStore.logo$.pipe(takeUntil(this.ngUnsubscribeState)).subscribe(logo => {
      this.hideSpinner(true);
      if (logo !== null && logo !== undefined) {
        this.isImageSaved$ = of(true);
        this.promotionLogoUrl$ = of(logo.url);
        this.promotionLogoFormGroup.patchValue({ 'uploadedLogo': true });
        this.snackBar.open("Promotion image saved successfully.", "Dismiss", {
          duration: 3000
        });
      }
      else {
        this.isImageSaved$ = of(false);
        this.promotionLogoUrl$ = of(this.placeholderUrl);
        this.promotionLogoFormGroup.patchValue({ 'uploadedLogo': '' });
      }
    });
  }

  public ngOnDestroy() : void {
    this.isImageSaved$ = of(false);
    this.promotionLogoUrl$ = of(this.placeholderUrl);
    this.promotionLogoFormGroup.patchValue({ 'uploadedLogo': '' });
    this.promotionLogoComponentStore.reset({});

    this.ngUnsubscribeState.next();
    this.ngUnsubscribeState.complete();
  }

  public ngOnChanges(changes : SimpleChanges) : void {
    if (!changes.promotion.firstChange) {
      this.promotionLogoComponentStore.getPromotionLogo({ companyId: this.companyId, promotionId: this.promotionId });
    }
  }

  public cancel() : void {
    this.location.back();
  }

  public savePromotion() : void {
    this.promotionDetailsFormGroup.markAllAsTouched();

    if (this.promotionDetailsFormGroup.valid) {
      this.showSpinner();

      const createPromotion = new CreatePromotionModel();
      createPromotion.name = this.promotionDetailsFormGroup.value.name;
      createPromotion.description = this.promotionDetailsFormGroup.value.description;
      createPromotion.startDate = this.promotionDetailsFormGroup.value.startDate;
      createPromotion.endDate = this.promotionDetailsFormGroup.value.endDate;
      createPromotion.startDateVoucher = this.promotionDetailsFormGroup.value.startDateVoucher;
      createPromotion.endDateVoucher = this.promotionDetailsFormGroup.value.endDateVoucher;

      createPromotion.promotionRewardName = this.promotionDetailsFormGroup.value.promotionRewardName;
      createPromotion.promotionRewardDescription = this.promotionDetailsFormGroup.value.promotionRewardDescription;
      createPromotion.recurrencePattern = this.promotionDetailsFormGroup.value.recurrencePattern;

      this.createPromotionComponentStore.createPromotion({ companyId: this.companyId, createPromotionModel: createPromotion });
    }
  }

  public onRecurrencePatternChanged($event): void {
    this.promotionDetailsFormGroup.patchValue({ recurrencePattern: $event });
  }

  public finishOnBoarding(): void {
    this.snackBar.open("Promotion created successfully.", "Dismiss");
    this.router.navigate(['/companies/' + this.companyId + '/promotions/' + this.promotionId]);
  }

  public fileChangeEvent(fileInput: any) : boolean {
    if (fileInput?.target?.files && fileInput?.target?.files[0]) {
      if (fileInput.target.files[0].size > this.max_size) {
        const errorMessage =
          'Maximum size allowed is ' + Math.floor(this.max_size / 1000) + 'Kb';
        this.snackBar.open(errorMessage, "Dismiss");
        return false;
      }

      if (!_.includes(this.allowed_types, fileInput.target.files[0].type)) {
        const errorMessage = 'Only Images are allowed ( JPG | PNG )';
        this.snackBar.open(errorMessage, "Dismiss");
        return false;
      }

      const uploadPhoto = new UploadPhotoModel();

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height > this.max_height || img_width > this.max_width) {
            const errorMessage =
              'Dimensions required ' +
              this.max_height +
              '*' +
              this.max_width +
              'px';
            this.snackBar.open(errorMessage, "Dismiss");
            return false;
          } 
          else if (img_height < this.min_height || img_width < this.min_width) {
            const errorMessage =
              'Dimensions required ' +
              this.min_height +
              '*' +
              this.min_width +
              'px';
            this.snackBar.open(errorMessage, "Dismiss");
            return false;
          } else {
            const imgBase64Path = e.target.result;
            uploadPhoto.fileContentBase64 = imgBase64Path.split(',')[1];
            this.promotionLogoComponentStore.uploadPromotionLogo({ companyId: this.companyId, promotionId: this.promotionId, uploadPhoto: uploadPhoto });
            return true;
          }
        };
      };

      const logo = fileInput.target.files[0];

      uploadPhoto.title = 'Promotion Logo';
      uploadPhoto.fileName = 'logo' + this._determineFileExtension(logo.type);

      reader.readAsDataURL(logo);
      return true;
    }
    return true;
  }

  public removeImage() : void {
    this.promotionLogoComponentStore.deletePromotionLogo({ companyId: this.companyId, promotionId: this.promotionId });
  }

  public isXLarge(): boolean {
    return this._isXLarge;
  }

  public isLarge(): boolean {
    return this._isLarge;
  }

  public isMedium(): boolean {
    return this._isMedium;
  }

  public isSmall(): boolean {
    return this._isSmall;
  }

  public isXSmall(): boolean {
    return this._isXSmall;
  }

  private _determineFileExtension(fileType: string): string {
    switch (fileType) {
      case 'image/jpg':
      case 'image/jpeg':
        return '.jpg';
      case 'image/png':
        return '.png';
    }
    throw new Error('Unsupported file type:' + fileType);
  }

  private showSpinner() : void {
    this.loadingSpinnerDialogRef = this.spinnerService.show();
  }

  private hideSpinner(loaded: boolean) : void {
    if (loaded && this.loadingSpinnerDialogRef !== null) {
      this.spinnerService.hide(this.loadingSpinnerDialogRef);
      this.loadingSpinnerDialogRef = null;
    }
  }
}
