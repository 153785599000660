<div [ngClass]="{
    'container-xlarge': isXLarge(),
    'container-large': isLarge(),
    'container-medium': isMedium(),
    'container-small': isSmall(),
    'container-xsmall': isXSmall()
  }">
    <div class="content-banner-row">
        <div [ngClass]="{
        'container-content-xlarge': isXLarge(),
        'container-content-large': isLarge(),
        'container-content-medium': isMedium(),
        'container-content-small': isSmall(),
        'container-content-xsmall': isXSmall()
      }">
            <div [ngClass]="{
          'content-xlarge': isXLarge(),
          'content-large': isLarge(),
          'content-medium': isMedium(),
          'content-small': isSmall(),
          'content-xsmall': isXSmall()
        }">
                <h3>{{ customerStorySummary?.readingTime }} read</h3>
                <h1><span class="primary-title">{{ customerStorySummary?.description }}</span></h1>
                <h2>
                    <p><span>Miss Nice Banana wants people to eat less from a box and more from the earth. They trust in
                            the power of nature, to provide everything for a healthy and balanced life. It’s all about
                            natural ingredients!</span></p>
                    <p><span>They serve their Caribbean dishes in a cozy foodbar and provide a positive, healthy, vegan
                            and glutenfree experience.</span></p>
                </h2>
            </div>
        </div>
        <div [ngClass]="{
        'container-product-banner-image-xlarge': isXLarge(),
        'container-product-banner-image-large': isLarge(),
        'container-product-banner-image-medium': isMedium(),
        'container-product-banner-image-small': isSmall(),
        'container-product-banner-image-xsmall': isXSmall()
      }">
            <img src="{{ customerStorySummary?.storyImageUrl }}" alt="{{ customerStorySummary?.description }}">
        </div>
    </div>

    <div class="content-banner-row">
        <div [ngClass]="{
            'container-stampcard-image-xlarge': isXLarge(),
            'container-stampcard-image-large': isLarge(),
            'container-stampcard-image-medium': isMedium(),
            'container-stampcard-image-small': isSmall(),
            'container-stampcard-image-xsmall': isXSmall()
          }">
            <img src="/assets/img/partners/stories/miss-nice-banana-stampcard.png" alt="Miss Nice Banana's stamp card">
        </div>
        <div [ngClass]="{
        'content-questions-xlarge': isXLarge(),
        'content-questions-large': isLarge(),
        'content-questions-medium': isMedium(),
        'content-questions-small': isSmall(),
        'content-questions-xsmall': isXSmall()
      }">
            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>How did you come about a loyalty program?</strong></span>
                </div>
                <span><em>I wanted a way to reward my loyal customers, attract new customers and encourage them to keep
                        coming back. With Planet VG I can do it all in one place!</em></span>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>Many businesses choose a paper loyalty card. Why have you chosen a digital
                            version?</strong></span>
                </div>
                <p><span><em>For practical reasons, mostly. People don’t always carry their wallets anymore, so they can
                            easily forget or lose a paper loyalty card. I do not want my customers to be disappointed at
                            the end of their visit when they can’t receive a stamp because they forgot or lost their
                            loyalty card.
                        </em></span></p>
                <p><span><em>I focus on creating a great experience for my customers, and the loyalty card needs to
                            elevate this experience, not diminish it. A digital loyalty card can be seamlessly
                            integrated into my customers’ visit, as people always carry their phone and thus always have
                            access to their loyalty card.
                        </em></span></p>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>Why is it important for you to reward your most loyal customers?</strong></span>
                </div>
                <span><em>It is a way to show gratitude to my loyal customers. The connection with my customers is very
                        important to me as a business owner. By rewarding them, I can create a deeper connection with my
                        customers. They keep coming back to support me and my business and I want to take the extra step
                        to show them that they are truly appreciated.
                    </em></span>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>While looking for solutions, what were the most important factors for
                            you?</strong></span>
                </div>
                <span><em>As a conscious entrepreneur, I wanted a vegan solution that aligns with my values.
                    </em></span>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>You chose Planet VG. What attracted you to use our solution?</strong></span>
                </div>
                <span><em>Planet VG ticked all my boxes. I don’t have to compromise on my values as it is made for
                        vegans by vegans.
                    </em></span>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>Can you tell us more about your loyalty program? What do you offer to your customers
                            and why?</strong></span>
                </div>
                <p><span><em>Our loyalty program is focussed on desserts, because who doesn’t like something sweet after
                            a nice meal? From a business strategy point of view, giving away something tangible like a
                            dessert creates a more memorable experience for a customer, than just a discount on their
                            bill.
                        </em></span></p>
                <p><span><em>As people love sharing an indulgent moment like a dessert, this kind of a reward stimulates
                            my customers to bring along friends, who can become new customers.
                        </em></span></p>
            </div>

            <div class="container-content-question">
                <div class="content-question">
                    <mat-icon color="primary">eco</mat-icon>
                    <span><strong>Almost there! Would you recommend Planet VG to other businesses you might
                            know?</strong></span>
                </div>
                <span><em>Definitely!! I would recommend Planet VG to all sustainable vegan businesses, not only as a
                        loyalty platform, but also as an entrepreneurs community tool.</em></span>
            </div>
        </div>
    </div>
</div>
<app-bottom-menu></app-bottom-menu>