<div class="container">
  <div class="container-content">
    <div class="container-content-banner">
      <h1>How it works</h1>
      <h2>
        Planet VG empowers your business to create stronger and lasting connections
        with your customers, whether they are new or returning.
      </h2>
      <h3>
        Build the best loyalty experience. Easily create your digital loyalty stamp cards, promotions and redeem
        vouchers with our <span class="primary-title">eco-</span>, <span class="primary-title">animal-</span> and
        <span class="primary-title">user-friendly</span> loyalty app. 
      </h3>
    </div>
  </div>
</div>
