export const environment = {
  production: true,
  apiUrl: "https://dev-loyalty.planetvg.nl",
  stsServer: "https://dev-auth.planetvg.nl/realms/planetvg",
  stripe_apiKey: "pk_test_51NG5UpFgaM4GvMFgdx8l6lHa203NILE9vbtSe4iqW3NWOHnyJvUR3D86rrqI3cE54qVTWLEscdaXKTVqh9NrhHus00LEeRmHZX",
  accountPortalUrl: "https://dev-account.planetvg.nl",
  // Crude Feature Flag
  enabledCheckout : true,
  storefrontUrl : "https://dev-www.planetvg.nl",
  analyticsKey : '97876ff8-1b9b-4ec2-ac71-58b8c0f93a5a'
};
